import React, { useState, useEffect, useRef, useCallback } from 'react';
import TechnicianDetailsModal from '../modals/TechnicianDetailsModal';
import TechnicianEditModal from '../modals/TechnicianEditModal';
import TechniciansToolbar from '../toolbar/TechniciansToolbar';
import Dots from '../../assets/svg/dots.svg';
import './Views.css';

const Technicians = ({ user }) => {
	const [technicians, setTechnicians] = useState([]);
	const [filteredTechnicians, setFilteredTechnicians] = useState([]);
	const [selectedTechnician, setSelectedTechnician] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const dotsRef = useRef([]);

	const fetchTechnicians = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/account-technicians/${user.account_id}/account`);
			if (!response.ok) {
				throw new Error('Failed to fetch technicians');
			}
			const data = await response.json();
			const techniciansWithMenu = data.map((tech) => ({ ...tech, isOpen: false }));
			setTechnicians(techniciansWithMenu);
			setFilteredTechnicians(techniciansWithMenu);
		} catch (error) {
			console.error('Error fetching account technicians:', error);
		} finally {
			setLoading(false);
		}
	}, [user.account_id]);

	useEffect(() => {
		fetchTechnicians();
	}, [fetchTechnicians]);

	const handleRowClick = (technicianId) => {
		setSelectedTechnician(technicianId);
		setShowModal(true);
	};

	const handleEditClick = (technicianId) => {
		setSelectedTechnician(technicianId);
		setShowEditModal(true);
	};

	const handleDeleteClick = async (technician) => {
		setSelectedTechnician(technician.id);
		const action = technician.is_active === '1' ? 'deactivate' : 'activate';

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/technicians/${technician.id}/${action}`, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Failed to delete technician');
			}
			fetchTechnicians();
		} catch (error) {
			console.error('Error deleting technician:', error);
		}
	};

	const handleMenuToggle = (index) => {
		setTechnicians((prevTechnicians) => prevTechnicians.map((technician, i) => (i === index ? { ...technician, isOpen: !technician.isOpen } : { ...technician, isOpen: false })));
		setFilteredTechnicians((prevFilteredTechnicians) =>
			prevFilteredTechnicians.map((technician, i) => (i === index ? { ...technician, isOpen: !technician.isOpen } : { ...technician, isOpen: false }))
		);
	};

	const handleDotsMenuClick = (index, e) => {
		e.stopPropagation();
		handleMenuToggle(index);
	};

	const filterTechnicians = (filterText) => {
		const filtered = technicians.filter((technician) => {
			return (
				technician.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
				technician.last_name.toLowerCase().includes(filterText.toLowerCase()) ||
				technician.email.toLowerCase().includes(filterText.toLowerCase())
			);
		});
		setFilteredTechnicians(
			filtered.map((filteredTechnician) => {
				const originalTechnician = technicians.find((tech) => tech.id === filteredTechnician.id);
				return { ...filteredTechnician, isOpen: originalTechnician.isOpen };
			})
		);
	};

	const handleMenuAction = (action, technicianId, technician) => {
		switch (action) {
			case 'view':
				handleRowClick(technicianId);
				break;
			case 'edit':
				handleEditClick(technicianId);
				break;
			case 'delete':
				handleDeleteClick(technician);
				break;
			default:
				break;
		}
		setTechnicians((prevTechnicians) =>
			prevTechnicians.map((technician) => ({
				...technician,
				isOpen: false,
			}))
		);
		setFilteredTechnicians((prevFilteredTechnicians) =>
			prevFilteredTechnicians.map((technician) => ({
				...technician,
				isOpen: false,
			}))
		);
	};

	const handleOutsideClick = useCallback((event) => {
		const dropdowns = document.querySelectorAll('.dropdownDot');
		let isClickedInside = false;

		dropdowns.forEach((dropdown) => {
			if (dropdown.contains(event.target)) {
				isClickedInside = true;
			}
		});

		if (!isClickedInside) {
			setTechnicians((prevTechnicians) => prevTechnicians.map((technician) => ({ ...technician, isOpen: false })));
			setFilteredTechnicians((prevFilteredTechnicians) => prevFilteredTechnicians.map((technician) => ({ ...technician, isOpen: false })));
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [handleOutsideClick]);

	if (loading) return <div className="loader"></div>;

	return (
		<div>
			<TechniciansToolbar technicians={technicians} setFilteredTechnicians={setFilteredTechnicians} setTechnicians={setTechnicians} user={user} filterTechnicians={filterTechnicians} />
			<div>
				<table className="customer-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>City</th>
						</tr>
					</thead>
					<tbody>
						{filteredTechnicians.map((technician, index) => (
							<tr key={technician.id}>
								<td className="firstColumn">
									{technician.first_name} {technician.last_name}
								</td>
								<td>{technician.email}</td>
								<td>
									{technician.city}, {technician.province},{technician.country}
								</td>
								<td>
									<div className="dotsContainer">
										<button className="dotsMenu" onClick={(e) => handleDotsMenuClick(index, e)} ref={(el) => (dotsRef.current[index] = el)}>
											<img src={Dots} alt="Filter" />
										</button>
										{technician.isOpen && (
											<div className="dropdownDot">
												<button
													onClick={(e) => {
														e.stopPropagation();
														handleMenuAction('view', technician.id, technician);
													}}
												>
													View
												</button>
												<button
													onClick={(e) => {
														e.stopPropagation();
														handleMenuAction('edit', technician.id, technician);
													}}
												>
													Edit
												</button>
												<button
													onClick={(e) => {
														e.stopPropagation();
														handleMenuAction('delete', technician.id, technician);
													}}
												>
													{technician.is_active === '1' ? 'Deactivate' : 'Activate'}
												</button>
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{showModal && <TechnicianDetailsModal selectedTechnician={selectedTechnician} handleCloseModal={() => setShowModal(false)} />}
			{showEditModal && (
				<TechnicianEditModal
					selectedTechnician={selectedTechnician}
					handleCloseModal={() => {
						setShowEditModal(false);
						fetchTechnicians();
					}}
				/>
			)}
		</div>
	);
};

export default Technicians;
