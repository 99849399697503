import React, { useState, useEffect } from 'react';
import './details.css';

const TechnicianDetailsModal = ({ selectedTechnician, handleCloseModal }) => {
	const [technicianDetails, setTechnicianDetails] = useState(null);

	useEffect(() => {
		const fetchTechnicianDetails = async () => {
			try {
				const technicianResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/technician/${selectedTechnician}`);
				if (!technicianResponse.ok) {
					throw new Error('Failed to fetch technician');
				}
				const technicianData = await technicianResponse.json();
				setTechnicianDetails(technicianData);
			} catch (error) {
				console.error('Error fetching technician details:', error);
			}
		};

		fetchTechnicianDetails();
	}, [selectedTechnician]);

	const formatDOB = (dob) => {
		const date = new Date(dob);
		const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		return formattedDate;
	};

	return (
		<div className="tech-modal-overlay">
			<div className="tech-detModal">
				<div className="tech-modal-header">
					<h2 className="tech-header-text">Technician Details</h2>
					<span className="tech-close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="tech-customerdetailsmodal-content">
					{technicianDetails && (
						<>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Name:</label>
									<p className="tech-cells">
										{technicianDetails.first_name} {technicianDetails.last_name}
									</p>
								</div>
								<div className="tech-details-cell">
									<label className="tech-details-titles">Email:</label>
									<p className="tech-cells">{technicianDetails.email}</p>
								</div>
							</div>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Country:</label>
									<p className="tech-cells">{technicianDetails.country}</p>
								</div>
								<div className="tech-details-cell">
									<label className="tech-details-titles">City:</label>
									<p className="tech-cells">{technicianDetails.city}</p>
								</div>
							</div>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Date of Birth:</label>
									<p className="tech-cells">{formatDOB(technicianDetails.dateofbirth)}</p>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TechnicianDetailsModal;
