import React, { useState, useEffect } from 'react';
import './details.css';

const TechnicianDetailsModal = ({ selectedCustomer, handleCloseModal }) => {
	const [customerDetails, setCustomerDetails] = useState(null);

	useEffect(() => {
		const fetchCustomerDetails = async () => {
			try {
				const customersResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customer/${selectedCustomer}`);
				if (!customersResponse.ok) {
					throw new Error('Failed to fetch customer');
				}
				const customerData = await customersResponse.json();
				setCustomerDetails(customerData);
			} catch (error) {
				console.error('Error fetching technician details:', error);
			}
		};

		fetchCustomerDetails();
	}, [selectedCustomer]);

	return (
		<div className="tech-modal-overlay">
			<div className="tech-detModal">
				<div className="tech-modal-header">
					<h2 className="tech-header-text">Customer Details</h2>
					<span className="tech-close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="tech-customerdetailsmodal-content">
					{customerDetails && (
						<>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Name</label>
									<p className="tech-cells">{customerDetails.first_name + ' ' + customerDetails.last_name}</p>
								</div>
								<div className="tech-details-cell">
									<label className="tech-details-titles">Email</label>
									<p className="tech-cells">{customerDetails.email}</p>
								</div>
							</div>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Country</label>
									<p className="tech-cells">{customerDetails.country}</p>
								</div>
								<div className="tech-details-cell">
									<label className="tech-details-titles">Province</label>
									<p className="tech-cells">{customerDetails.province}</p>
								</div>
								<div className="tech-details-cell">
									<label className="tech-details-titles">City</label>
									<p className="tech-cells">{customerDetails.city}</p>
								</div>
							</div>
							<div className="tech-detailsRow">
								<div className="tech-details-cell">
									<label className="tech-details-titles">Phone</label>
									<p className="tech-cells">{customerDetails.phone_number}</p>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TechnicianDetailsModal;
