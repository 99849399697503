import React, { useState, useEffect, useCallback } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './details.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const CustomerEditModal = ({ selectedCustomer, handleCloseModal }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [customerDetails, setCustomerDetails] = useState(null);
	const [clicked, setClicked] = useState(false);

	const fetchCustomerDetails = useCallback(async () => {
		try {
			const customerResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customer/${selectedCustomer}`);
			if (!customerResponse.ok) {
				throw new Error('Failed to fetch customer');
			}
			const customerData = await customerResponse.json();
			setCustomerDetails(customerData);
		} catch (error) {
			console.error('Error fetching customer details:', error);
		}
	}, [selectedCustomer]);

	useEffect(() => {
		fetchCustomerDetails();
	}, [fetchCustomerDetails]);

	const handleInputChange = (name, value) => {
		if (name === 'phone_number') {
			// Handle phone number input separately
			setCustomerDetails((prevData) => ({
				...prevData,
				phone_number: value || 'NaN', // Update phone number
			}));
		} else if (name === 'country') {
			setCustomerDetails((prevData) => ({
				...prevData,
				country: value, // Update country
			}));
		} else if (name === 'region') {
			setCustomerDetails((prevData) => ({
				...prevData,
				province: value, // Update region
			}));
		} else {
			// For other input fields
			setCustomerDetails((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		setClicked(false);
	};

	const onSave = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customer/${selectedCustomer}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(customerDetails),
			});

			if (!response.ok) {
				throw new Error('Failed to save customer details');
			}
			handleCloseModal();
			setIsEditing(false);
			fetchCustomerDetails();
		} catch (error) {
			console.error('Error saving customer details:', error);
			// Handle error (e.g., display an error message to the user)
		}
	};

	if (!customerDetails) {
		return <div>Loading...</div>;
	}

	const handleButtonClick = () => {
		if (
			!customerDetails.first_name.trim() ||
			!customerDetails.last_name.trim() ||
			!customerDetails.email.trim() ||
			!customerDetails.country.trim() ||
			!customerDetails.province.trim() ||
			!customerDetails.city.trim() ||
			!customerDetails.phone_number.trim()
		) {
			setClicked(true);
			console.log('Button clicked on disabled state');
		}
	};

	return (
		<div className="tech-modal-overlay">
			<div className="tech-detModal">
				<div className="tech-modal-header">
					<h2 className="tech-header-text">Customer Details</h2>
					<span className="tech-close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="tech-customerdetailsmodal-content">
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Name</label>
							<input
								type="text"
								name="first_name"
								value={customerDetails.first_name}
								placeholder="First Name"
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<input
								type="text"
								name="last_name"
								value={customerDetails.last_name}
								placeholder="Last Name"
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
					</div>
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Contact</label>
							<input
								type="email"
								name="email"
								placeholder="Email"
								value={customerDetails.email}
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<PhoneInput
								type="tel"
								name="phone"
								placeholder="Phone"
								value={customerDetails.phone_number}
								onChange={(value) => handleInputChange('phone_number', value)}
								disabled={!isEditing}
							/>
						</div>
					</div>
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Address</label>
							<CountryDropdown
								type="text"
								name="country"
								placeholder="Country"
								value={customerDetails.country}
								className="CountryDropdown"
								onChange={(value) => handleInputChange('country', value)}
								disabled={!isEditing}
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<RegionDropdown
								type="text"
								name="province"
								country={customerDetails.country}
								value={customerDetails.province}
								onChange={(value) => handleInputChange('region', value)}
								disabled={!isEditing}
								className="RegionDropdown"
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<input type="text" name="city" value={customerDetails.city} onChange={(e) => handleInputChange(e.target.name, e.target.value)} disabled={!isEditing} />
						</div>
					</div>
					<div className="tech-button-container">
						{isEditing && (
							<button
								className="tech-save-button"
								onClick={onSave}
								disabled={
									!customerDetails.first_name.trim() ||
									!customerDetails.last_name.trim() ||
									!customerDetails.email.trim() ||
									!customerDetails.country.trim() ||
									!customerDetails.province.trim() ||
									!customerDetails.city.trim()
								}
								onPointerDown={handleButtonClick}
							>
								Save Changes
							</button>
						)}
						{!isEditing && (
							<button className="tech-edit-button" onClick={() => setIsEditing(true)}>
								Edit
							</button>
						)}
					</div>
					{clicked && <p style={{ color: 'red' }}>Please fill out all of the fields before saving </p>}
				</div>
			</div>
		</div>
	);
};

export default CustomerEditModal;
