// Daily.js

import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import './calendar.css';
import JobOrderDetailsModal from '../modals/JobOrderDetailsModal';
import Toolbar from '../toolbar/Toolbar';
import { ServiceRequestsData, useFetchUserRole } from '../../hooks/AccountDataFetching';

const Daily = (user) => {
	// State to store events and the selected event for modal display
	const [events, setEvents] = useState([]);
	const [filteredEvents, setFilteredEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const { userRole, loading, error } = useFetchUserRole(user.user);
	const [loadingEvents, setLoadingEvents] = useState(true); // Add loading state for events

	useEffect(() => {
		if (userRole) {
			const fetchData = async () => {
				try {
					const formattedEvents = await ServiceRequestsData(user.user);
					setEvents(formattedEvents);
				} catch (error) {
					console.error('Error fetching service requests:', error);
				} finally {
					setLoadingEvents(false); // Set loading to false after fetching data
				}
			};

			fetchData();
		}
	}, [userRole, user]);

	if (loading) return <div className="loader"></div>;
	if (error) return <p>Error: {error.message}</p>;

	// Handler for clicking on a calendar event
	const handleEventClick = (info) => {
		setSelectedEvent(info.event);
	};

	// Custom style for the list events
	const customListEventContent = ({ event }) => {
		const listStyle = {
			display: 'flex',
			justifyContent: 'space-around',
			alignItems: 'center',
		};

		const titleStyle = {
			color: '#000',
			fontWeight: '600',
			fontSize: '20px',
		};

		const timeStyle = {
			color: '#000',
			fontWeight: '600',
			fontSize: '15px',
		};

		const userStyle = {
			color: '#898888',
			fontWeight: '600',
			fontSize: '15px',
		};

		return (
			<div style={listStyle}>
				<p style={timeStyle}>{event.start.toLocaleString()}</p>
				<p style={titleStyle}>{event.title}</p>
				<p style={userStyle}>{event.extendedProps.assignedTechs}</p>
			</div>
		);
	};

	return (
		<div>
			<Toolbar setEvents={setEvents} setFilteredEvents={setFilteredEvents} user={user} />

			{loadingEvents ? (
				<div className="loader"></div>
			) : (
				<FullCalendar
					plugins={[listPlugin]}
					initialView="listDay"
					events={filteredEvents.length > 0 ? filteredEvents : events}
					eventClick={handleEventClick}
					headerToolbar={{
						left: 'prev',
						center: 'title',
						right: 'next',
					}}
					eventContent={customListEventContent}
				/>
			)}

			{selectedEvent && <JobOrderDetailsModal selectedEvent={selectedEvent} handleCloseModal={() => setSelectedEvent(null)} setEvents={setEvents} events={events} user={user} />}
		</div>
	);
};

export default Daily;
