import React, { useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Header from './components/header/Header';
import MyCalendar from './components/calendar/MyCalendar';
import Weekly from './components/calendar/Weekly';
import Daily from './components/calendar/Daily';
import Technicians from './components/views/Technicians';
import List from './components/calendar/List';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import CompleteProfile from './components/auth/CompleteProfile';
import Customers from './components/views/Customers';
import DataSources from './components/connections/DataSources';
import UserAccount from './components/userProfile/UserAccount';
import ServiceInventory from './components/views/ServiceInventory';
import ForgotPassword from './components/auth/ForgotPassword';

import { useFetchUserRole } from './hooks/AccountDataFetching';
import { UserContext } from './context/UserContext';

import './App.css';

function App() {
	const { user, setUser } = useContext(UserContext);
	const { userRole } = useFetchUserRole(user);

	const [activeTab, setActiveTab] = useState('Monthly');
	const timeoutIdRef = useRef(null);

	useEffect(() => {
		const storedUser = localStorage.getItem('user');
		if (storedUser) {
			setUser(JSON.parse(storedUser));
		}
	}, [setUser]);

	const handleLogout = () => {
		setUser(null);
		localStorage.removeItem('user');
		clearTimeout(timeoutIdRef.current);
	};

	const startTimeout = () => {
		timeoutIdRef.current = setTimeout(() => {
			handleLogout();
		}, 10 * 60 * 1000); // 10 minutes
	};

	const resetTimeout = () => {
		clearTimeout(timeoutIdRef.current);
		startTimeout();
	};

	useEffect(() => {
		const events = ['mousemove', 'keydown', 'click', 'scroll'];
		const reset = () => resetTimeout();

		if (user) {
			events.forEach((event) => window.addEventListener(event, reset));
			startTimeout();
		}

		return () => {
			events.forEach((event) => window.removeEventListener(event, reset));
			clearTimeout(timeoutIdRef.current);
		};
	});

	const handleTabChange = (tab) => {
		setActiveTab(tab);
		resetTimeout();
	};

	const handleLogin = (loggedInUser) => {
		setUser(loggedInUser);
		localStorage.setItem('user', JSON.stringify(loggedInUser));
		startTimeout();
	};

	const adminRoutes = (
		<Switch>
			<Route exact path="/monthly" render={() => <MyCalendar user={user} />} />
			<Route exact path="/technicians" render={() => <Technicians user={user} />} />
			<Route exact path="/weekly" render={() => <Weekly user={user} />} />
			<Route exact path="/daily" render={() => <Daily user={user} />} />
			<Route exact path="/list" render={() => <List user={user} />} />
			<Route exact path="/customers" render={() => <Customers user={user} />} />
			<Route exact path="/dataSources" render={() => <DataSources user={user} />} />
			<Route exact path="/account" render={() => <UserAccount user={user} />} />
			<Route exact path="/services-inventory" render={() => <ServiceInventory user={user} />} />
			<Redirect from="/login" to="/monthly" />
			<Redirect from="/" to="/monthly" />
		</Switch>
	);

	const technicianRoutes = (
		<Switch>
			<Route exact path="/monthly" render={() => <MyCalendar user={user} />} />
			<Route exact path="/weekly" render={() => <Weekly user={user} />} />
			<Route exact path="/daily" render={() => <Daily user={user} />} />
			<Route exact path="/list" render={() => <List user={user} />} />
			<Route exact path="/account" render={() => <UserAccount user={user} />} />
			<Redirect from="/login" to="/monthly" />
			<Redirect from="/" to="/monthly" />
		</Switch>
	);

	return (
		<Router>
			<div className="App">
				<div id="page-wrap">
					{user ? (
						<>
							<Header activeTab={activeTab} onTabChange={handleTabChange} onLogout={handleLogout} user={user} />
							<div className="calendar-margin">{userRole === 'admin' ? adminRoutes : technicianRoutes}</div>
						</>
					) : (
						<Switch>
							<Route exact path="/login" render={() => <Login onLogin={handleLogin} />} />
							<Route exact path="/signup" render={() => <Signup />} />
							<Route exact path="/complete-profile" render={() => <CompleteProfile />} />
							<Route exact path="/forgotPassword" render={() => <ForgotPassword />} />
							<Redirect from="/" to="/login" />
						</Switch>
					)}
				</div>
			</div>
		</Router>
	);
}

export default App;
