import { useState, useEffect } from 'react';

const useFetchServices = (user) => {
	const [services, setServices] = useState([]);
	const [filteredServices, setFilteredServices] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchServices = async () => {
			try {
				const servicesResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/inventory/services_inventory/${user.account_id}`);
				if (!servicesResponse.ok) {
					throw new Error('Failed to fetch services');
				}
				const servicesData = await servicesResponse.json();

				const servicesWithMenu = servicesData.map((category) => ({
					...category,
					services: category.services.map((service) => ({
						...service,
						isOpen: false,
					})),
				}));
				setServices(servicesWithMenu);
				setFilteredServices(servicesWithMenu);
			} catch (error) {
				console.error('Error fetching services:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchServices();
	}, [user]);

	return { services, setServices, filteredServices, setFilteredServices, loading };
};

export default useFetchServices;
