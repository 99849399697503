import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { ServiceRequestsData, useFetchAccountTechnicians, useFetchAccountCustomers, useFetchPriorities } from '../../hooks/AccountDataFetching';
import ProfileImages from '../../assets/svg/ProfileImages.svg';
import './modals.css';
const AddJobOrderModal = ({ handleCloseModal, setEvents }) => {
	const { user, accountId } = useContext(UserContext);

	const { technicians } = useFetchAccountTechnicians(user);
	const { customers } = useFetchAccountCustomers(user);
	const { priorities } = useFetchPriorities(user);

	const [eventDate, setEventDate] = useState('');
	const [eventTime, setEventTime] = useState('');
	const [orderCustomer, setOrderCustomer] = useState('');
	const [assignedTechnicians, setAssignedTechnicians] = useState([]);
	const [description, setDescription] = useState('');
	const [status] = useState('2'); // 2 is for open
	const [selectedPriority, setSelectedPriority] = useState('');
	const [eventDuration, setEventDuration] = useState('');
	const [selectedProblem, setSelectedProblem] = useState('');
	const [clicked, setClicked] = useState(false);

	const [problems, setProblems] = useState([]);

	useEffect(() => {
		const fetchProblems = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/service_problems/${accountId}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();
				setProblems(data);
			} catch (error) {
				console.error('Error fetching problems', error);
			}
		};

		fetchProblems();
	}, [accountId]);

	const formatFullTitle = (problem) => {
		const fullTitleArray = [problem.category_title, ...problem.full_title];
		return fullTitleArray.filter((title) => title).join(' - ');
	};

	const loadData = async () => {
		try {
			const formattedEvents = await ServiceRequestsData(user);
			setEvents(formattedEvents);
		} catch (err) {
			console.error(err);
		}
	};

	const handleAddClick = async () => {
		try {
			const startDatetime = new Date(`${eventDate}T${eventTime}`);
			const estimate_end_datetime = calculateEndDatetime(startDatetime, parseInt(eventDuration, 10));

			const orderResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/service_requests/add-service-request`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					request_datetime: startDatetime.toISOString(),
					estimate_end_datetime: estimate_end_datetime.toISOString(),
					request_description: description,
					account_id: user.account_id,
					status_id: status,
					priority_id: selectedPriority,
					customer_id: orderCustomer,
					assignedTech_ids: assignedTechnicians,
					createdby_id: user.id,
					service_id: selectedProblem,
				}),
			});

			if (!orderResponse.ok) {
				console.error('Failed to add order');
				return;
			}

			const loadUpdatedData = async () => {
				try {
					const updatedFormattedEvents = await ServiceRequestsData(user);
					setEvents(updatedFormattedEvents);
				} catch (err) {
					console.error(err);
				}
			};

			loadUpdatedData();
			loadData();

			handleCloseModal();
		} catch (error) {
			console.error('Error adding order', error);
		}
	};

	const handleTechnicianChange = (e) => {
		const selectedTechId = parseInt(e.target.value, 10); // Convert to a number
		setClicked(false);

		if (assignedTechnicians.includes(selectedTechId)) {
			setAssignedTechnicians((prev) => prev.filter((id) => id !== selectedTechId));
		} else {
			setAssignedTechnicians((prev) => [...prev, selectedTechId]);
		}
	};

	const handleButtonClick = () => {
		if (!selectedProblem || !description || !orderCustomer || !eventDuration || !assignedTechnicians.length || !eventDate || !eventDuration || !selectedPriority) {
			setClicked(true);
			// setTimeout(() => {
			// 	setClicked(false);
			// }, 3000);
			console.log('Button clicked on disabled state');
		}
	};

	const calculateEndDatetime = (startDatetime, durationInHours) => {
		const endDatetime = new Date(startDatetime);
		endDatetime.setHours(startDatetime.getHours() + durationInHours);
		return endDatetime;
	};

	return (
		<div className="modal-overlay">
			<div className="addjob-modal">
				<div className="modal-header">
					<p className="header-text">Add Job</p>
					<span className="close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="modal-content">
					<div className="row-sec">
						<div className="addModalRow">
							<label className="column input-titles">Priority</label>
							<label className="column input-titles">Date</label>
							<label className="column input-titles">Time</label>
						</div>
						<div className="addModalRow">
							<div className="column">
								<label className="addCellWidth">
									<select
										className="addCell dropdowncell inputCells"
										value={selectedPriority}
										onChange={(e) => {
											setSelectedPriority(e.target.value);
											setClicked(false);
										}}
									>
										<option value="" disabled>
											Select Job Priority
										</option>
										{priorities.map((priority) => {
											const priorityClass = `priority-title ${priority.id === 1 ? 'priority-gray' : priority.id === 2 ? 'priority-green' : 'priority-red'}`;
											return (
												<option key={priority.id} value={priority.id} className={priorityClass}>
													{priority.priority_name}
												</option>
											);
										})}
									</select>
								</label>
							</div>
							<div className="column">
								<input
									className="addCell inputCells"
									type="date"
									value={eventDate}
									onChange={(e) => {
										setEventDate(e.target.value);
										setClicked(false);
									}}
								/>
							</div>
							<div className="column">
								<input
									className="addCell inputCells"
									type="time"
									value={eventTime}
									onChange={(e) => {
										setEventTime(e.target.value);
										setClicked(false);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="row-sec">
						<div className="addModalRow">
							<div className="column input-titles">Technician</div>
							<div className="column input-titles">Service Problem</div>
							<label className="column input-titles">Duration (in h)</label>
						</div>
						<div className="addModalRow">
							<div className="column technicianList">
								<label className="addCellWidth">
									<select className="addCell dropdowncell inputCells" value="" onChange={handleTechnicianChange}>
										<option value="" disabled>
											Select Technician
										</option>
										{technicians.map((technician) => (
											<option key={technician.id} value={technician.id}>
												{`${technician.first_name} ${technician.last_name}`}
											</option>
										))}
									</select>
								</label>
								<div className="technicianListDetails">
									{assignedTechnicians.length > 0 && technicians.length > 0 && (
										<ul>
											{assignedTechnicians.map((id) => {
												const technician = technicians.find((tech) => tech.id === id);
												if (technician) {
													return (
														<li key={id}>
															<img src={ProfileImages} alt="Profile" className="ProfileImagesIcons" />
															{`${technician.first_name} ${technician.last_name}`}
														</li>
													);
												} else {
													console.warn(`Technician with id ${id} not found in the technicians list`);
													return null;
												}
											})}
										</ul>
									)}
									{technicians.length === 0 && assignedTechnicians.length > 0 && <p>Loading technicians...</p>}
								</div>
							</div>
							<div className="column">
								<label className="addCellWidth">
									<select
										className="addCell dropdowncell inputCells"
										value={selectedProblem}
										onChange={(e) => {
											setSelectedProblem(e.target.value);
											setClicked(false);
										}}
									>
										<option value="" disabled>
											Select Service Problem
										</option>
										{problems.map((problem) => (
											<option key={problem.id} value={problem.last_child_id}>
												{formatFullTitle(problem)}
											</option>
										))}
									</select>
								</label>
							</div>
							<div className="column">
								<input
									className="addCell inputCells"
									type="number"
									value={eventDuration}
									onChange={(e) => {
										setEventDuration(e.target.value);
										setClicked(false);
									}}
								/>
							</div>
						</div>
					</div>

					<div className="row-sec">
						<div className="addModalRow">
							<div className="column input-titles">Customer</div>
							<div className="column span-two input-titles">Description</div>
						</div>
						<div className="addModalRow">
							<div className="column">
								<label className="addCellWidth">
									<select
										className="addCell dropdowncell inputCells"
										value={orderCustomer}
										onChange={(e) => {
											setOrderCustomer(e.target.value);
											setClicked(false);
										}}
									>
										<option value="" disabled>
											Select Customer
										</option>
										{customers.map((customer) => (
											<option key={customer.id} value={customer.id}>
												{`${customer.first_name} ${customer.last_name}`}
											</option>
										))}
									</select>
								</label>
							</div>

							<div className="column span-two">
								<textarea
									rows="5"
									className="descriptionTextArea span-two"
									placeholder="Enter job description"
									value={description}
									onChange={(e) => {
										setDescription(e.target.value);
										setClicked(false);
									}}
								/>
							</div>
						</div>
					</div>

					<div className="row-sec">
						<button
							className="full-width-cell add-button"
							type="button"
							onClick={handleAddClick}
							disabled={!selectedProblem || !description || !orderCustomer || !eventDuration || !assignedTechnicians.length || !eventDate || !eventDuration || !selectedPriority} // Updated to check for multiple technicians
							onPointerDown={handleButtonClick}
						>
							Add
						</button>
					</div>
					{clicked && <p style={{ color: 'red' }}>Please fill out all of the fields before sending the invitation</p>}
				</div>
			</div>
		</div>
	);
};

export default AddJobOrderModal;
