import React from 'react';
// import AddJobOrderModal from '../modals/AddJobOrderModal';
import './Toolbar.css';
// import { useFetchUserRole } from '../../hooks/AccountDataFetching';

const WeeklyToolbar = ({ setEvents, user }) => {
	// const { userRole } = useFetchUserRole(user.user);

	// const [isAddModalOpen, setAddModalOpen] = useState(false);

	// const handleAddEventClick = () => {
	// 	setAddModalOpen(true);
	// };

	// const handleCloseAddModal = () => {
	// 	setAddModalOpen(false);
	// };

	return (
		<div className="navigator-container">
			<div>
				{/* {userRole === 'admin' && ( */}
				{/* <button className="nav-button" onClick={handleAddEventClick}>
					Add Job Order
				</button> */}
				{/* )} */}
			</div>

			{/* {isAddModalOpen && <AddJobOrderModal handleCloseModal={handleCloseAddModal} setEvents={setEvents} user={user} />} */}
		</div>
	);
};

export default WeeklyToolbar;
