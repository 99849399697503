import React, { useState, useEffect, useCallback } from 'react';
import './details.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const TechnicianEditModal = ({ selectedTechnician, handleCloseModal }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [technicianDetails, setTechnicianDetails] = useState(null);
	const [isUnderAge, setIsUnderAge] = useState(false);
	const [clicked, setClicked] = useState(false);
	console.log(technicianDetails);

	const fetchTechnicianDetails = useCallback(async () => {
		try {
			const technicianResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/technician/${selectedTechnician}`);
			if (!technicianResponse.ok) {
				throw new Error('Failed to fetch technician');
			}
			const technicianData = await technicianResponse.json();
			updateTechnicianDetails(technicianData);
		} catch (error) {
			console.error('Error fetching technician details:', error);
		}
	}, [selectedTechnician]);

	const updateTechnicianDetails = (data) => {
		setTechnicianDetails(data);
	};

	useEffect(() => {
		fetchTechnicianDetails();
	}, [fetchTechnicianDetails]);

	const handleInputChange = (name, value) => {
		if (name === 'dateofbirth') {
			const newDOB = value;
			const currentDate = new Date();
			const selectedDOB = new Date(newDOB);

			// Calculate age based on the selected date of birth
			let age = currentDate.getFullYear() - selectedDOB.getFullYear();

			

			if (age < 18) {
				// Technician is under 18 years old, prevent editing the date of birth
				console.error('Technician is under 18 years old. Cannot edit date of birth.');
				// setIsEditing(false);
				setIsUnderAge(true);

				return;
			}
			setIsUnderAge(false);
		} else if (name === 'country') {
			setTechnicianDetails((prevData) => ({
				...prevData,
				country: value, // Update country
			}));
		} else if (name === 'province') {
			setTechnicianDetails((prevData) => ({
				...prevData,
				province: value, // Update region
			}));
		}

		setTechnicianDetails((prevDetails) => ({
			...prevDetails,
			[name]: value,
		}));
		setClicked(false);
	};

	const onSave = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/technician/${selectedTechnician}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(technicianDetails),
			});

			if (!response.ok) {
				throw new Error('Failed to save technician details');
			}
			handleCloseModal();
			setIsEditing(false);
			fetchTechnicianDetails();
		} catch (error) {
			console.error('Error saving technician details:', error);
			// Handle error (e.g., display an error message to the user)
		}
	};

	if (!technicianDetails) {
		return <div>Loading...</div>;
	}

	const formatDOB = (dob) => {
		const date = new Date(dob);
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
		const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
		const formattedDate = `${date.getFullYear()}-${month}-${day}`;
		return formattedDate;
	};

	const handleButtonClick = () => {
		if (
			!technicianDetails.first_name.trim() ||
			!technicianDetails.last_name.trim() ||
			!technicianDetails.email.trim() ||
			!technicianDetails.country.trim() ||
			!technicianDetails.province.trim() ||
			!technicianDetails.city.trim() ||
			!technicianDetails.dateofbirth.trim()
		) {
			setClicked(true);
			console.log('Button clicked on disabled state');
		}
	};

	return (
		<div className="tech-modal-overlay">
			<div className="tech-detModal">
				<div className="tech-modal-header">
					<h2 className="tech-header-text">Technician Details</h2>
					<span className="tech-close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="tech-customerdetailsmodal-content">
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Name</label>
							<input
								type="text"
								name="first_name"
								value={technicianDetails.first_name}
								placeholder="First Name"
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<input
								type="text"
								name="last_name"
								value={technicianDetails.last_name}
								placeholder="Last Name"
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
					</div>
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Contact</label>
							<input
								type="email"
								name="email"
								placeholder="Email"
								value={technicianDetails.email}
								onChange={(e) => handleInputChange(e.target.name, e.target.value)}
								disabled={!isEditing}
							/>
						</div>
					</div>
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Address</label>
							<CountryDropdown
								type="text"
								name="country"
								value={technicianDetails.country}
								className="CountryDropdown"
								onChange={(value) => handleInputChange('country', value)}
								disabled={!isEditing}
							/>
						</div>
						<div className="tech-details-cell">
							<br></br>
							<RegionDropdown
								type="text"
								name="province"
								country={technicianDetails.country}
								value={technicianDetails.province}
								onChange={(value) => handleInputChange('province', value)}
								disabled={!isEditing}
								className="RegionDropdown"
							/>
						</div>

						<div className="tech-details-cell">
							<br></br>
							<input type="text" name="city" value={technicianDetails.city} onChange={(e) => handleInputChange(e.target.name, e.target.value)} disabled={!isEditing} />
						</div>
					</div>
					<div className="tech-detailsRow">
						<div className="tech-details-cell">
							<label className="tech-details-titles">Date of Birth</label>
							<input
								type="date"
								name="dateofbirth"
								value={formatDOB(technicianDetails.dateofbirth)}
								onChange={(e) => handleInputChange('dateofbirth', e.target.value)}
								disabled={!isEditing}
								style={{ border: isUnderAge ? '1px solid red' : '1px solid #ccc' }}
							/>
						</div>
					</div>
					<div className="tech-button-container">
						{isEditing && (
							<button
								className="tech-save-button"
								onClick={onSave}
								disabled={
									!technicianDetails.first_name.trim() ||
									!technicianDetails.last_name.trim() ||
									!technicianDetails.email.trim() ||
									!technicianDetails.country.trim() ||
									!technicianDetails.province.trim() ||
									!technicianDetails.city.trim() ||
									!technicianDetails.dateofbirth.trim()
								}
								onPointerDown={handleButtonClick}
							>
								Save Changes
							</button>
						)}
						{!isEditing && (
							<button className="tech-edit-button" onClick={() => setIsEditing(true)}>
								Edit
							</button>
						)}
					</div>
					{isUnderAge && <p style={{ color: 'red' }}>Technician must be older than 18 years old</p>}
					{clicked && <p style={{ color: 'red' }}>Please fill out all of the fields before saving </p>}
				</div>
			</div>
		</div>
	);
};

export default TechnicianEditModal;
