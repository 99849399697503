import React, { useState, useContext } from 'react';
import { ServiceRequestsData, useFetchUserRole } from '../../hooks/AccountDataFetching';
import { UserContext } from '../../context/UserContext';
import './modals.css';

const JobOrderDetailsModal = ({ selectedEvent, handleCloseModal, setEvents, events }) => {
	const { user, accountId } = useContext(UserContext);
	const { userRole } = useFetchUserRole(user);

	const [isUpdateMode, setUpdateMode] = useState(false);
	const [updatedDescription, setUpdatedDescription] = useState(selectedEvent.extendedProps.description);

	// Function to fetch updated events data
	const loadData = async () => {
		try {
			const formattedEvents = await ServiceRequestsData(user);
			setEvents(formattedEvents);
		} catch (err) {
			console.error(err);
		}
	};

	// Function to handle the "Update" button click todo
	const handleUpdateClick = async () => {
		try {
			// Ask for confirmation before updating
			const isConfirmed = window.confirm('Are you sure you want to update this event?');

			if (!isConfirmed) {
				return;
			}

			// Send a POST request to update the selected order
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/service_requests/update/${selectedEvent.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					request_description: updatedDescription,
				}),
			});

			if (!response.ok) {
				console.error('Failed to update order');
				return;
			}

			const updatedOrdersResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/services/accounts/${accountId}/service_requests`);
			const updatedOrders = await updatedOrdersResponse.json();

			setEvents(updatedOrders);
			loadData();

			// Close the modal
			handleCloseModal();
		} catch (error) {
			console.error('Error updating order', error);
		}
	};

	// Function to handle the "Delete" button click
	const handleDeleteClick = async () => {
		try {
			// Ask for confirmation before deleting
			const isConfirmed = window.confirm('Are you sure you want to delete this event?');

			if (!isConfirmed) {
				return;
			}

			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/service_requests/${selectedEvent.id}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				console.error('Failed to delete event');
				return;
			}

			const updatedEvents = events.filter((event) => event.id !== selectedEvent.id);
			setEvents(updatedEvents);
			loadData();

			handleCloseModal();
		} catch (error) {
			console.error('Error deleting event', error);
		}
	};

	const handleInvoiceClick = async () => {
		try {
			const invoiceResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/quickbooks/invoice/${user.id}`, {
				mode: 'no-cors',
			});
			console.log('invoice===', invoiceResponse);
			if (!invoiceResponse.ok) {
				console.error('Failed to get invoice');
				return;
			}
		} catch (error) {
			console.error('Error generating invoice', error);
		}
	};

	return (
		<div className="modal-overlay">
			<div className="addjob-modal detModal">
				<div className="modal-header">
					<h2 className="header-text">{isUpdateMode ? 'Update Job' : 'Job Details'}</h2>
					<span className="close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="modal-content">
					<div className="row-sec">
						<div className="addModalRow">
							<label className="column input-titles">Priority</label>
							<label className="column input-titles">Date</label>
							<label className="column input-titles">Time</label>
						</div>
						<div className="addModalRow">
							<p className="addCellWidth column priority_title">{selectedEvent.extendedProps.priority}</p>
							<p className="addCellWidth column">{selectedEvent.start.toLocaleString()}</p>
							<p className="addCellWidth column">{selectedEvent.start.toLocaleString()}</p>
						</div>
					</div>

					<div className="row-sec">
						<div className="addModalRow">
							<label className="column input-titles">Assignor</label>
							<label className="column input-titles">Assigned Technician</label>
							<label className="column input-titles">Estimate End Time</label>
						</div>
						<div className="addModalRow">
							<p className="addCellWidth column ">{selectedEvent.extendedProps.assignor}</p>
							<p className="addCellWidth column">{selectedEvent.extendedProps.assignedTechs}</p>
							<p className="addCellWidth column">{selectedEvent.end.toLocaleString()}</p>
						</div>
					</div>

					<div className="row-sec">
						<div className="addModalRow">
							<div className="column input-titles">Status</div>
							<label className="column span-two input-titles">Service Title:</label>
						</div>
						<div className="addModalRow">
							<div className="addCellWidth column">
								<p className="status_title">{selectedEvent.extendedProps.status}</p>
							</div>
							<p className="span-two order-title">{selectedEvent.title}</p>
						</div>
					</div>

					<div className="row-sec">
						{isUpdateMode ? (
							// Update mode content
							<>
								<div className="addModalRow">
									<div className="column input-titles">Customer</div>
									<label className="column span-two input-titles">Updated Description:</label>
								</div>
								<div className="addModalRow">
									<p className="addCellWidth column">{selectedEvent.extendedProps.customer}</p>
									<textarea className="full-width-cell description" value={updatedDescription} onChange={(e) => setUpdatedDescription(e.target.value)} />
								</div>
							</>
						) : (
							// Details mode content
							<>
								<div className="addModalRow">
									<div className="column input-titles">Customer</div>
									<label className="column span-two input-titles">Description:</label>
								</div>
								<div className="addModalRow">
									<p className="addCellWidth column details_titles">{selectedEvent.extendedProps.customer}</p>
									<p className="full-width-cell description">{selectedEvent.extendedProps.description}</p>
								</div>
							</>
						)}
					</div>

					<div className="row-sec">
						{userRole === 'admin' && (
							<>
								{isUpdateMode ? (
									// Update mode buttons
									<button className="full-width-cell add-button" onClick={handleUpdateClick}>
										Update Job
									</button>
								) : (
									// Details mode buttons
									<>
										<button className="details-button add-button" onClick={() => setUpdateMode(true)}>
											Edit Details
										</button>
										<button className="details-button add-button" onClick={handleDeleteClick}>
											Delete Job
										</button>
										<button className="details-button add-button" onClick={handleInvoiceClick}>
											Generate Invoice
										</button>
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobOrderDetailsModal;
