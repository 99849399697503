import React, { useState, useContext, useEffect, useCallback } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { UserContext } from '../../context/UserContext';
import './modals.css';

const AddCustomerModal = ({ handleCloseModal }) => {
	const { accountId } = useContext(UserContext);
	const [setCustomers] = useState({});

	const [formData, setFormData] = useState({
		email: '',
		firstName: '',
		lastName: '',
		phone_number: '',
		country: '',
		province: '',
		city: '',
		is_active: 1,
	});

	const fetchCustomerDetails = useCallback(async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${accountId}/account`);
			if (!response.ok) {
				throw new Error('Failed to fetch customer');
			}
			const customerData = await response.json();
			setCustomers(customerData);
		} catch (error) {
			console.error('Error fetching customer details:', error);
		}
	}, [accountId, setCustomers]);

	useEffect(() => {
		fetchCustomerDetails();
	}, [fetchCustomerDetails]);
	const [clicked, setClicked] = useState(false);

	const handleChange = (name, value) => {
		if (name === 'phone_number') {
			setFormData((prevData) => ({
				...prevData,
				phone_number: value || 'NaN',
			}));
		} else if (name === 'country') {
			setFormData((prevData) => ({
				...prevData,
				country: value,
			}));
		} else if (name === 'province') {
			setFormData((prevData) => ({
				...prevData,
				province: value,
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		setClicked(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${accountId}/account`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			if (!response.ok) {
				throw new Error('Failed to add customer');
			}
			handleCloseModal();
			fetchCustomerDetails();
		} catch (error) {
			console.error('Error adding customer:', error);
		}
	};

	const handleButtonClick = () => {
		if (
			!formData.firstName.trim() ||
			!formData.lastName.trim() ||
			!formData.email.trim() ||
			!formData.country.trim() ||
			!formData.province.trim() ||
			!formData.city.trim() ||
			!formData.phone_number.trim()
		) {
			setClicked(true);
			console.log('Button clicked on disabled state');
		} else {
			console.log('Form is complete');
		}
	};

	return (
		<div className="modal-overlay">
			<div className="addcustomer-modal">
				<div className="modal-header">
					<p className="header-text">Add Customer</p>
					<span className="close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>

				<div className="modal-content">
					<div className="row-sec">
						<div className="addRow">
							<label className="input-titles">Name</label>
						</div>
						<div className="addRow">
							<input
								className="addTechCell"
								type="text"
								name="firstName"
								placeholder="First Name"
								value={formData.firstName}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								required
							/>
							<input
								className="addTechCell"
								type="text"
								placeholder="Last Name"
								name="lastName"
								value={formData.lastName}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="row-sec">
						<div className="addRow">
							<label className="input-titles">Contact</label>
						</div>
						<div className="addRow">
							<input
								className="addTechCell"
								type="email"
								placeholder="Email Address"
								name="email"
								value={formData.email}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								required
							/>
							<PhoneInput className="addTechCell" placeholder="Phone Number" value={formData.phone_number} onChange={(value) => handleChange('phone_number', value)} />
						</div>
					</div>

					<div className="row-sec">
						<div className="addRow">
							<label className="input-titles">Address</label>
						</div>
						<div className="addCustomerModalRow">
							<CountryDropdown
								className="addTechCell customer-column"
								placeholder="Country"
								name="country"
								value={formData.country}
								onChange={(value) => handleChange('country', value)}
							/>
							{formData.country && (
								<RegionDropdown
									className="addTechCell customer-column"
									placeholder="Province"
									name="province"
									country={formData.country}
									value={formData.province}
									onChange={(value) => handleChange('province', value)}
								/>
							)}
							<input
								className="addTechCell customer-column"
								placeholder="City"
								type="text"
								name="city"
								value={formData.city}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</div>

					<div className="row-sec">
						<button
							className="add-button"
							type="submit"
							onClick={handleSubmit}
							disabled={!formData.firstName || !formData.email || !formData.lastName || !formData.city || !formData.country || !formData.province || !formData.phone_number}
							onPointerDown={handleButtonClick}
						>
							Add
						</button>
					</div>
					{clicked && <p style={{ color: 'red' }}>Please fill out all of the fields before saving </p>}
				</div>
			</div>
		</div>
	);
};

export default AddCustomerModal;
