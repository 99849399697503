// AccountDataFetching.js hooks
import { useState, useEffect } from 'react';

export const ServiceRequestsData = async (user) => {
	try {
		// Fetch user role first
		const roleResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/user/${user.id}/role`);
		if (!roleResponse.ok) {
			throw new Error('Failed to fetch user role');
		}
		const roleData = await roleResponse.json();
		const userRole = roleData.user_type;

		let response;
		if (userRole === 'technician' && userRole !== 'admin') {
			response = await fetch(`${process.env.REACT_APP_API_URL}/api/service_requests/technician/${user.id}`);
		} else {
			response = await fetch(`${process.env.REACT_APP_API_URL}/api/service_requests/account/${user.account_id}`);
		}

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();

		const { serviceRequests, priorities, technicians, services, statuses, customers, techToRequests } = data;

		const formattedEvents = serviceRequests.map(({ id, request_datetime, estimate_end_datetime, request_description, customer_id, createdby_id, priority_id, status_id, service_id }) => {
			const creatorUser = technicians.find((technician) => technician.id === createdby_id);
			const assignedTechs = techToRequests
				.filter((techToRequest) => techToRequest.request_id === id)
				.map((techToRequest) => {
					const tech = technicians.find((technician) => technician.id === techToRequest.tech_id);
					return tech ? `${tech.first_name} ${tech.last_name}` : 'Undefined';
				});
			const service = services.find((serv) => serv.id === service_id);
			const priority = priorities.find((pri) => pri.id === priority_id);
			const status = statuses.find((stat) => stat.id === status_id);
			const customer = customers.find((cust) => {
				return cust.id === customer_id;
			});

			return {
				title: service ? service.title : 'Unknown Service',
				start: request_datetime,
				end: estimate_end_datetime,
				id: id,
				description: request_description,
				customer: customer ? `${customer.first_name} ${customer.last_name}` : 'Unknown Customer',
				assignedTechs: assignedTechs.length > 0 ? assignedTechs.join(', ') : 'Undefined',
				assignor: creatorUser ? `${creatorUser.first_name} ${creatorUser.last_name}` : 'Undefined',
				backgroundColor: '#EA7500',
				borderColor: '#EA7500',
				priority: priority ? priority.priority_name : 'Unknown Priority',
				status: status ? status.status_name : 'Unknown Status',
			};
		});

		return formattedEvents;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchUserRole = (user) => {
	const [userRole, setUserRole] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchUserRole = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/user/${user.id}/role`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech role');
				}
				const data = await response.json();
				setUserRole(data.user_type);
			} catch (error) {
				console.error(error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchUserRole();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { userRole, loading, error };
};

export const useFetchAccountTechnicians = (user) => {
	const [technicians, setTechnicians] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAccountTechnicians = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/techs/${user.account_id}/account`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech');
				}
				const techniciansData = await response.json();
				setTechnicians(techniciansData);
			} catch (error) {
				console.error('Error fetching technicians', error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchAccountTechnicians();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { technicians, loading, error };
};

export const useFetchAccountTechniciansFilter = (user) => {
	const [technicians, setTechnicians] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAccountTechnicians = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/techs/${user.user.account_id}/account`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech');
				}
				const techniciansData = await response.json();
				setTechnicians(techniciansData);
			} catch (error) {
				console.error('Error fetching technicians', error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchAccountTechnicians();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { technicians, loading, error };
};

export const useFetchAccountCustomers = (user) => {
	const [customers, setCustomers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAccountCustomers = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${user.account_id}/account`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech role');
				}
				const customersData = await response.json();
				setCustomers(customersData);
			} catch (error) {
				console.error('Error fetching technicians', error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchAccountCustomers();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { customers, loading, error };
};
export const useFetchAccountCustomersFilter = (user) => {
	const [customers, setCustomers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAccountCustomers = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${user.user.account_id}/account`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech role');
				}
				const customersData = await response.json();
				setCustomers(customersData);
			} catch (error) {
				console.error('Error fetching technicians', error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchAccountCustomers();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { customers, loading, error };
};

export const useFetchPriorities = (user) => {
	const [priorities, setPriorities] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchPriorities = async () => {
			try {
				setLoading(true);
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/priority`);
				if (!response.ok) {
					throw new Error('Failed to fetch tech role');
				}
				const data = await response.json();
				setPriorities(data);
			} catch (error) {
				console.error('Error fetching technicians', error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		if (user) {
			fetchPriorities();
		} else {
			setLoading(false);
		}
	}, [user]);

	return { priorities, loading, error };
};
