import React, { useState } from 'react';
import './auth.css';
import { Link } from 'react-router-dom';
import FullLogo from '../../assets/svg/fullLogo.svg';

const Login = ({ onLogin }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginError, setLoginError] = useState(null);

	const handleLogin = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password }),
			});

			if (response.ok) {
				const user = await response.json();
				onLogin(user);
				setLoginError(null);
			} else {
				setLoginError('Incorrect email or password.');
				console.error('Login failed');
			}
		} catch (error) {
			console.error('Error during login:', error);
			setLoginError('An error occurred during Login.');
		}
	};

	const handlePasswordKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleLogin();
		}
	};

	return (
		<div className="authContainer">
			<div className="logoContainer">
				<img src={FullLogo} alt="Logo" className="FullLogo" />
			</div>
			<div className="login-container">
				<p className="title-header">Sign-in</p>
				<div className="input-group">
					<input className="logInput" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
				</div>
				<div className="input-group">
					<input className="logInput" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handlePasswordKeyDown} />
				</div>
				{loginError && <p className="err-message">{loginError}</p>}
				<div className="centeredButton">
					<button className="logButton" onClick={handleLogin}>
						Sign in
					</button>
				</div>
			</div>
			<div className="outerText">
				<p className="signup-text">
					<Link to="/forgotPassword" className="signup-link">
						Forgot your password
					</Link>
					?
				</p>
				<p className="signup-text">
					<Link to="/signup" className="signup-link">
						Sign up
					</Link>
				</p>
			</div>
		</div>
	);
};

export default Login;
