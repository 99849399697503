import React, { useState, useEffect } from 'react';
import './auth.css';
import { Link, useHistory } from 'react-router-dom';
import FullLogo from '../../assets/svg/fullLogo.svg';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const Signup = () => {
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [country, setCountry] = useState('');
	const [province, setProvince] = useState('');
	const [city, setCity] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [isTechnician, setIsTechnician] = useState(false);
	const [verificationCode, setVerificationCode] = useState('');
	const [verificationSent, setVerificationSent] = useState(false);
	const [hashedCode, setHashedCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isUnderAge, setIsUnderAge] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (step === 2 && !verificationSent) {
			handleSendVerification();
		}
	});

	const handleDateOfBirthChange = (e) => {
		console.log(e);
		const newDOB = e;
		const currentDate = new Date();
		const selectedDOB = new Date(newDOB);

		// Calculate age based on the selected date of birth
		let age = currentDate.getFullYear() - selectedDOB.getFullYear();

		if (age < 18) {
			// Technician is under 18 years old, prevent editing the date of birth
			console.error('Technician is under 18 years old. Cannot edit date of birth.');

			setIsUnderAge(true);

			return;
		}

		setDateOfBirth(e);
		setIsUnderAge(false);
	};

	const handleNext = async () => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

		if (step === 1 && !emailRegex.test(email)) {
			setErrorMessage('Invalid email address');
			console.error('Invalid email address');
			return;
		}

		// Check if the email does not exist
		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/emails?email=${email}`);
		const data = await response.json();

		if (data.exists) {
			setErrorMessage('Email already exists in the database');
			console.error('Email already exists in the database');
			return;
		}

		if (step === 2 && !verificationSent) {
			await handleSendVerification();
			return;
		}

		if (step === 2) {
			// Compare entered code with the original verification code
			if (verificationCode === hashedCode) {
				console.log('Verification code is correct');
				setStep(step + 1);
			} else {
				setErrorMessage('Verification code is incorrect');
				console.error('Verification code is incorrect');
			}
			return;
		}

		if (step === 3 && password !== confirmPassword) {
			setPasswordsMatch(false);
			return;
		}

		setErrorMessage('');

		setStep(step + 1);
		setPasswordsMatch(true);
	};

	const handleSendVerification = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/users/send-verification`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
				}),
			});

			if (response.ok) {
				const data = await response.json();
				setVerificationSent(true);
				setHashedCode(data.hashedCode);
				console.log('Verification email sent successfully');
			} else {
				console.log('Failed to send verification email');
			}
		} catch (error) {
			console.error('Error sending verification email:', error);
		}
	};

	const handleSignup = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/admins`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					companyName,
					email,
					password,
					firstName,
					lastName,
					dateOfBirth,
					country,
					province,
					city,
					isTechnician,
				}),
			});

			if (response.ok) {
				console.log('Signup successful');
				history.push('/login');
			} else {
				console.log('Signup failed');
			}
		} catch (error) {
			console.error('Error during signup:', error);
		}
	};

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<>
						<div className="input-group">
							<input className="logInput" type="email" placeholder="Email" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						{errorMessage && <p className="err-message">{errorMessage}</p>}
						<button className="logButton" onClick={handleNext} disabled={!email}>
							Next
						</button>
					</>
				);
			case 2:
				return (
					<>
						{verificationSent ? (
							<>
								<div className="input-group">
									<input className="logInput" type="text" placeholder="Verification Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
								</div>
								{errorMessage && <p className="err-message">{errorMessage}</p>}
								<button className="logButton" onClick={handleNext} disabled={!verificationCode}>
									Verify Code
								</button>
							</>
						) : (
							<p className="err-message2">Verification code will be sent to your email.</p>
						)}
					</>
				);
			case 3:
				return (
					<>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
							{!passwordsMatch && <p className="err-message">Passwords do not match.</p>}
						</div>
						<button className="logButton" onClick={handleNext} disabled={!password || !confirmPassword}>
							Next
						</button>
					</>
				);
			case 4:
				return (
					<div className="grid-container">
						<div className="row">
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
							</div>
							<div className="input-group regcell">
								<input type="checkbox" checked={isTechnician} onChange={(e) => setIsTechnician(e.target.checked)} />
								<label>Is Technician</label>
							</div>
						</div>
						<div className="row">
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
							</div>
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
							</div>
						</div>
						<div className="row">
							<div className="regcell one-third">
								<p className="dateText">Date of Birth</p>
							</div>
							<div className="input-group regcell two-thirds">
								<input
									className="logInput"
									type="date"
									placeholder="Date of Birth"
									value={dateOfBirth}
									onChange={(e) => handleDateOfBirthChange(e.target.value)}
									style={{ border: isUnderAge ? '1px solid red' : '1px solid #ccc' }}
								/>
							</div>
						</div>
						<div className="row">
							<div className="input-group regcell">
								<CountryDropdown className="logInput" type="text" placeholder="Country" value={country} onChange={(value) => setCountry(value)} />
							</div>
							<div className="input-group regcell">
								<RegionDropdown className="logInput" type="text" country={country} placeholder="Province" value={province} onChange={(value) => setProvince(value)} />
							</div>
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
							</div>
						</div>

						<div className="row">
							<button className="logButton" onClick={handleSignup} disabled={!companyName || !firstName || !lastName || !dateOfBirth || !country || !province || !city}>
								Sign up
							</button>
							{isUnderAge && <p style={{ color: 'red' }}>Technician must be older than 18 years old</p>}
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="authContainer">
			<div className="logoContainer">
				<img src={FullLogo} alt="Logo" className="FullLogo" />
			</div>
			<div className="login-container">
				<p className="title-header">Sign-up</p>

				<div className="logContent">{renderStep()}</div>
			</div>
			{step !== 1 && (
				<div className="outerText">
					<p className="signup-link" onClick={() => setStep(step - 1)}>
						&lt; Back
					</p>
				</div>
			)}
			{step === 1 && (
				<div className="outerText">
					<p className="signup-text">
						&lt; &nbsp;
						<Link to="/login" className="signup-link">
							Back to Sign-in
						</Link>
					</p>
				</div>
			)}
		</div>
	);
};

export default Signup;
