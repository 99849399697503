// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
	text-align: center;
	overflow: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.calendar-margin {
	margin-left: 7%;
	margin-right: 7%;
	margin-top: 2.5%;
}

.fc-button-primary {
	background-color: #13d1ff !important;
	border-color: #13d1ff !important;
}

.loader {
	border: 8px solid #f3f3f3;
	border-top: 8px solid #13d1ff;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
	margin: 10% auto; /* Center the loader */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.fc-direction-ltr {
	margin-bottom: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,cAAc;CACd,oBAAoB;AACrB;;AAEA;CACC;EACC,4CAA4C;CAC7C;AACD;;AAEA;CACC,yBAAyB;CACzB,iBAAiB;CACjB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,6BAA6B;CAC7B,YAAY;AACb;;AAEA;CACC,cAAc;AACf;;AAEA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,yBAAyB;CAC1B;AACD;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,oCAAoC;CACpC,gCAAgC;AACjC;;AAEA;CACC,yBAAyB;CACzB,6BAA6B;CAC7B,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,kCAAkC;CAClC,gBAAgB,EAAE,sBAAsB;AACzC;;AAEA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,yBAAyB;CAC1B;AACD;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".App {\n\ttext-align: center;\n\toverflow: hidden;\n}\n\n.App-logo {\n\theight: 40vmin;\n\tpointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n\t.App-logo {\n\t\tanimation: App-logo-spin infinite 20s linear;\n\t}\n}\n\n.App-header {\n\tbackground-color: #282c34;\n\tmin-height: 100vh;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: calc(10px + 2vmin);\n\tcolor: white;\n}\n\n.App-link {\n\tcolor: #61dafb;\n}\n\n@keyframes App-logo-spin {\n\tfrom {\n\t\ttransform: rotate(0deg);\n\t}\n\tto {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.calendar-margin {\n\tmargin-left: 7%;\n\tmargin-right: 7%;\n\tmargin-top: 2.5%;\n}\n\n.fc-button-primary {\n\tbackground-color: #13d1ff !important;\n\tborder-color: #13d1ff !important;\n}\n\n.loader {\n\tborder: 8px solid #f3f3f3;\n\tborder-top: 8px solid #13d1ff;\n\tborder-radius: 50%;\n\twidth: 50px;\n\theight: 50px;\n\tanimation: spin 2s linear infinite;\n\tmargin: 10% auto; /* Center the loader */\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.fc-direction-ltr {\n\tmargin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
