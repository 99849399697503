// Services Inventory.js

import React from 'react';
import ServiceInventoryToolbar from '../toolbar/ServiceInventoryToolbar';
import useFetchServices from '../../hooks/InventoryHooks';
import Dots from '../../assets/svg/dots.svg';
import './Views.css';

const Services = ({ user }) => {
	const { services, setServices, filteredServices, setFilteredServices, loading } = useFetchServices(user);

	const handleMenuToggle = (categoryIndex, serviceIndex) => {
		setServices((prevServices) =>
			prevServices.map((category, cIndex) =>
				cIndex === categoryIndex
					? {
							...category,
							services: category.services.map((service, sIndex) => (sIndex === serviceIndex ? { ...service, isOpen: !service.isOpen } : service)),
					  }
					: category
			)
		);
	};

	const handleDotsMenuClick = (categoryIndex, serviceIndex, e) => {
		e.stopPropagation();
		handleMenuToggle(categoryIndex, serviceIndex);
	};

	const flattenServicePath = (service) => {
		const traverse = (node, path = []) => {
			const newPath = [...path, node.title];
			if (!node.children || node.children.length === 0) {
				return [newPath.join(' - ')];
			}
			return node.children.flatMap((child) => traverse(child, newPath));
		};

		return traverse(service);
	};

	return (
		<div>
			<ServiceInventoryToolbar services={services} setFilteredServices={setFilteredServices} setServices={setServices} user={user} />
			<div>
				{loading ? (
					<div className="loader"></div>
				) : (
					<table className="customer-table">
						<thead>
							<tr className="firstRow">
								<th>Category</th>
								<th>Service</th>
							</tr>
						</thead>
						<tbody>
							{filteredServices.map((category, categoryIndex) =>
								category.services.flatMap((service, serviceIndex) => {
									const servicePaths = flattenServicePath(service);
									return servicePaths.map((servicePath, pathIndex) => (
										<tr key={`${service.id}-${pathIndex}`}>
											<td>{category.category}</td>
											<td className="firstColumn">{servicePath}</td>
											<td>
												<div className="dotsContainer">
													<button className="dotsMenu" onClick={(e) => handleDotsMenuClick(categoryIndex, serviceIndex, e)}>
														<img src={Dots} alt="Filter" />
													</button>
												</div>
											</td>
										</tr>
									));
								})
							)}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Services;
