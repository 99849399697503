import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';

import WeeklyToolbar from '../toolbar/WeeklyToolbar';
import JobOrderDetailsModal from '../modals/JobOrderDetailsModal';
import { UserContext } from '../../context/UserContext';

import './calendar.css';

const Weekly = ({ user }) => {
	const { accountId } = useContext(UserContext);

	const [events, setEvents] = useState([]);
	const [resources, setResources] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [loading, setLoading] = useState(true);

	const resourceRef = useRef(null);
	const fetchData = useCallback(async () => {
		try {
			const combinedResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/service_requests/combined-weekly-data/${accountId}`);
			const combinedData = await combinedResponse.json();

			const { technicians, priorities, statuses, requests, services, techToRequests, customers } = combinedData;

			const formattedResources = technicians.map((technician) => ({
				id: technician.id.toString(),
				title: `${technician.first_name} ${technician.last_name}`,
			}));

			setResources(formattedResources);

			const formattedEvents = requests
				.map(({ id, request_datetime, estimate_end_datetime, request_description, customer_id, createdby_id, priority_id, status_id, service_id }) => {
					const creatorUser = technicians.find((technician) => technician.id === createdby_id);
					const assignedTechIds = techToRequests.filter((ttr) => ttr.request_id === id).map((ttr) => ttr.tech_id);
					const assignedTechs = assignedTechIds.map((tech_id) => technicians.find((tech) => tech.id === tech_id)).filter(Boolean);

					const assignedTechNames = assignedTechs.map((tech) => `${tech.first_name} ${tech.last_name}`).join(', ');

					const service = services.find((serv) => serv.id === service_id);
					const priority = priorities.find((pri) => pri.id === priority_id);
					const status = statuses.find((stat) => stat.id === status_id);
					const customer = customers.find((cust) => {
						return cust.id === customer_id;
					});

					if (!service) {
						console.error(`Service not found for service_id: ${service_id}`);
						return null; // Skip this event if the service is not found
					}

					return {
						title: service ? service.title : 'Unknown Service',
						start: request_datetime,
						end: estimate_end_datetime,
						id: id,
						description: request_description,
						customer: customer ? `${customer.first_name} ${customer.last_name}` : 'Unknown Customer',

						resourceId: assignedTechIds.length ? assignedTechIds[0] : null,
						assignedTechs: assignedTechNames || 'Undefined',
						assignor: creatorUser ? `${creatorUser.first_name} ${creatorUser.last_name}` : 'Undefined',
						backgroundColor: 'none',
						borderColor: '#ddd',
						priority: priority ? priority.priority_name : 'Unknown Priority',
						status: status ? status.status_name : 'Unknown Status',
					};
				})
				.filter((event) => event !== null); // Filter out any null events

			setEvents(formattedEvents);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, [accountId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (resourceRef.current) {
			setTimeout(() => {
				document.documentElement.style.setProperty('--resource-column-width', `15%`);
			}, 0);
		}
	}, [resources]);

	const handleEventClick = (info) => {
		setSelectedEvent(info.event);
	};
	function formatTime(timeText) {
		// Replace 'a' with 'AM' and 'p' with 'PM' in the time text
		const formattedTime = timeText.replaceAll('a', 'AM').replaceAll('p', 'PM');

		return formattedTime;
	}

	if (loading) return <div className="loader"></div>;

	return (
		<div>
			<WeeklyToolbar setEvents={setEvents} user={user} />
			<div ref={resourceRef} style={{ display: 'none' }}>
				{resources.map((resource) => (
					<div key={resource.id}>{resource.title}</div>
				))}
			</div>

			<FullCalendar
				plugins={[resourceTimelinePlugin, interactionPlugin]}
				initialView="resourceTimelineWeek"
				schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
				resources={resources}
				resourceAreaHeaderContent="Technicians"
				events={events}
				eventClick={handleEventClick}
				headerToolbar={{
					left: 'prev',
					center: 'title',
					right: 'next',
				}}
				views={{
					resourceTimelineWeek: {
						type: 'timeline',
						duration: { weeks: 1 },
						slotDuration: { days: 1 },
						slotEventOverlap: 'false',
						buttonText: 'Week',
						slotLabelContent: (dateInfo) => {
							const isSunday = dateInfo.date.getDay() === 0;
							const colorStyle = isSunday ? { color: '#FA2539' } : { color: '#010101' };
							const dayName = dateInfo.date.toLocaleDateString('en-US', {
								weekday: 'short',
							});
							const dayDate = dateInfo.date.getDate();
							return <span style={colorStyle}>{`${dayName} (${dayDate})`}</span>;
						},
						eventContent: function (arg) {
							const eventStyles = {
								height: '20vh',
								width: '100%',
								itemAlign: 'center',
								padding: '0px',
								margin: '0px',
							};
							const timeStyles = {
								borderBottom: '1px solid #ddd',
								padding: '5px',
								color: '#010101',
								fontWeight: 'bold',
								fontSize: '2vh',
							};
							const titleStyles = {
								padding: '5px',
								color: '#000000',
								fontSize: '2vh',
							};
							const techStyles = {
								padding: '5px',
								color: '#000000',
								fontSize: '2vh',
								textAlign: 'center',
							};

							return (
								<div style={eventStyles}>
									<p style={timeStyles}>{formatTime(arg.timeText)}</p>
									<p style={titleStyles}>{arg.event.title.charAt(0).toUpperCase() + arg.event.title.slice(1)}</p>
									<p style={techStyles}>{arg.event.extendedProps.assignedTechs}</p>
								</div>
							);
						},
					},
				}}
				resourceAreaWidth="var(--resource-column-width)"
			/>
			{selectedEvent && <JobOrderDetailsModal selectedEvent={selectedEvent} handleCloseModal={() => setSelectedEvent(null)} setEvents={setEvents} events={events} />}
		</div>
	);
};

export default Weekly;
