import React, { useState, useCallback } from 'react';
import Autosuggest from 'react-autosuggest';
import './Toolbar.css';
import SearchIcon from '../../assets/svg/search.svg';
import AddCustomerModal from '../modals/AddCustomerModal';

const CustomersToolbar = ({ customers, setFilteredCustomers, setCustomers, user }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [isAddModalOpen, setAddModalOpen] = useState(false);

	const fetchCustomers = useCallback(async () => {
		try {
			const customersResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${user.account_id}/account`);
			if (!customersResponse.ok) {
				throw new Error('Failed to fetch customers');
			}
			const customersData = await customersResponse.json();
			const customersWithMenu = customersData.map((customer) => ({
				...customer,
				isOpen: false,
			}));
			setCustomers(customersWithMenu);
			setFilteredCustomers(customersWithMenu);
		} catch (error) {
			console.error('Error fetching customers:', error);
		}
	}, [user.account_id, setCustomers, setFilteredCustomers]);

	const handleSearchChange = (event, { newValue }) => {
		setSearchTerm(newValue);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();

		const searchFilter =
			searchTerm === ''
				? customers
				: customers.filter(
						(customer) =>
							(customer.first_name && customer.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(customer.last_name && customer.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(customer.first_name + ' ' + customer.last_name).toLowerCase().includes(searchTerm.toLowerCase()) ||
							(customer.email && customer.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(customer.city && customer.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(customer.province && customer.province.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
							(customer.country && customer.country.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(customer.phone_number && customer.phone_number.includes(searchTerm))
				  );

		setFilteredCustomers(searchFilter);
	};

	const getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: customers.filter(
					(customer) =>
						(customer.first_name && customer.first_name.toLowerCase().includes(inputValue)) ||
						(customer.last_name && customer.last_name.toLowerCase().includes(inputValue)) ||
						(customer.email && customer.email.toLowerCase().includes(inputValue)) ||
						(customer.city && customer.city.toLowerCase().includes(inputValue)) ||
						(customer.province && customer.province.toLowerCase().includes(inputValue)) ||
						(customer.country && customer.country.toLowerCase().includes(inputValue)) ||
						(customer.phone_number && customer.phone_number.includes(inputValue))
			  );
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const renderSuggestion = (suggestion, { query }) => {
		const fullName = `${suggestion.first_name} ${suggestion.last_name}`;
		const email = suggestion.email;
		const phoneNumber = suggestion.phone_number;
		const city = suggestion.city;
		const country = suggestion.country;
		const province = suggestion.province;
		console.log(suggestion);
		const parts = fullName.split(new RegExp(`(${query})`, 'gi'));

		const showAdditionalInfo =
			email.toLowerCase().includes(query.toLowerCase()) ||
			phoneNumber.includes(query) ||
			city.toLowerCase().includes(query.toLowerCase()) ||
			province.toLowerCase().includes(query.toLowerCase()) ||
			country.toLowerCase().includes(query.toLowerCase());

		return (
			<div>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<span key={index} style={{ fontWeight: 'bold', color: '#13d1ff' }}>
							{part}
						</span>
					) : (
						part
					)
				)}
				{showAdditionalInfo && (
					<span style={{ color: '#13d1ff', marginLeft: '5px' }}>
						(
						{email.toLowerCase().includes(query.toLowerCase())
							? 'email'
							: phoneNumber.includes(query)
							? phoneNumber
							: city.toLowerCase().includes(query.toLowerCase())
							? 'In ' + city
							: province.toLowerCase().includes(query.toLowerCase())
							? 'In ' + province
							: country.toLowerCase().includes(query.toLowerCase())
							? 'In ' + country
							: ''}
						)
					</span>
				)}
			</div>
		);
	};

	const handleAddEventClick = () => {
		setAddModalOpen(true);
	};

	const handleCloseAddModal = () => {
		setAddModalOpen(false);
		fetchCustomers();
	};

	return (
		<div className="navigator-container">
			<div>
				<button className="nav-button" onClick={handleAddEventClick}>
					Add Customer
				</button>
			</div>

			<div className="rightSide">
				<form onSubmit={handleSearchSubmit}>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						getSuggestionValue={(suggestion) => suggestion.first_name + ' ' + suggestion.last_name}
						renderSuggestion={renderSuggestion}
						inputProps={{
							placeholder: 'Search',
							value: searchTerm,
							onChange: handleSearchChange,
						}}
					/>
					<button type="submit">
						<img src={SearchIcon} alt="Search" className="searchImage" />
					</button>
				</form>
			</div>

			{isAddModalOpen && <AddCustomerModal handleCloseModal={handleCloseAddModal} setCustomers={setCustomers} user={user} />}
		</div>
	);
};

export default CustomersToolbar;
