import React, { useState, useEffect, useRef, useCallback } from 'react';
import AddJobOrderModal from '../modals/AddJobOrderModal';
import './Toolbar.css';
import { ServiceRequestsData, useFetchUserRole, useFetchAccountTechniciansFilter, useFetchAccountCustomersFilter } from '../../hooks/AccountDataFetching';
import SearchIcon from '../../assets/svg/search.svg';
import FilterIcon from '../../assets/svg/filterIcon.svg';

const Toolbar = ({ setEvents, setFilteredEvents, user }) => {
	const { userRole } = useFetchUserRole(user.user);

	const { technicians } = useFetchAccountTechniciansFilter(user);
	const { customers } = useFetchAccountCustomersFilter(user);

	const [searchTerm, setSearchTerm] = useState('');

	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [allEvents, setAllEvents] = useState([]);
	const [filterOptions, setFilterOptions] = useState({
		startDate: '',
		customer: '',
		technician: '',
		status: '',
		priority: '',
	});
	const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);

	const filterMenuRef = useRef(null);

	useEffect(() => {
		if (userRole) {
			const fetchData = async () => {
				try {
					const originalEvents = await ServiceRequestsData(user.user);
					setAllEvents(originalEvents);
				} catch (error) {
					console.error('Error fetching service requests:', error);
				}
			};

			fetchData();
		}
	}, [userRole, user]);

	const handleClickOutside = useCallback(
		(event) => {
			const dropdowns = document.querySelectorAll('.nav-button');
			let isClickedInside = false;

			dropdowns.forEach((dropdown) => {
				if (dropdown.contains(event.target)) {
					isClickedInside = true;
				}
			});

			if (!isClickedInside && filterMenuRef.current && !filterMenuRef.current.contains(event.target)) {
				setFilterMenuOpen(false);
			}
		},
		[filterMenuRef, setFilterMenuOpen]
	);

	useEffect(() => {
		const handleClick = (event) => {
			handleClickOutside(event);
		};

		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [handleClickOutside]);

	const handleSearchChange = (e) => {
		const searchTerm = e.target.value;

		setSearchTerm(searchTerm);

		const searchFilter = searchTerm === '' ? allEvents : allEvents.filter((event) => event.title && event.title.toLowerCase().includes(searchTerm.toLowerCase()));

		setFilteredEvents(searchFilter);

		if (searchTerm === '') {
			setEvents(allEvents);
		}
	};

	const handleSearchSubmit = (e) => {
		e.preventDefault();

		const searchFilter = searchTerm === '' ? allEvents : allEvents.filter((event) => event.title && event.title.toLowerCase().includes(searchTerm.toLowerCase()));

		setFilteredEvents(searchFilter);
		setEvents(searchFilter);
	};

	const handleAddEventClick = () => {
		setAddModalOpen(true);
	};

	const handleCloseAddModal = () => {
		setAddModalOpen(false);
	};

	const handleFilterToggle = () => {
		setFilterMenuOpen(!isFilterMenuOpen);
	};

	const handleFilterChange = (key, value) => {
		console.log(`Filter change: ${key} = ${value}`); // Debug line
		setFilterOptions({ ...filterOptions, [key]: value });
	};

	const applyFilters = () => {
		let filteredEvents = [...allEvents];
		console.log('Applying filters with options:', filterOptions); // Debug line

		if (filterOptions.customer) {
			filteredEvents = filteredEvents.filter((event) => {
				const customer = customers.find((cust) => {
					return cust.id === parseInt(filterOptions.customer, 10);
				});

				if (customer) {
					console.log('Customer found: ' + customer.first_name);
				} else {
					console.log('Customer not found');
				}

				return customer && event.customer && event.customer.toLowerCase().includes(`${customer.first_name.toLowerCase()} ${customer.last_name.toLowerCase()}`);
			});
		}

		if (filterOptions.technician) {
			filteredEvents = filteredEvents.filter((event) => {
				const assignedTechnician = technicians.find((tech) => {
					console.log('tech id : ' + tech.id);
					return tech.id === parseInt(filterOptions.technician, 10);
				});
				if (assignedTechnician) {
					console.log('assigned: ' + assignedTechnician.first_name);
				} else {
					console.log('Technician not found');
				}

				return (
					assignedTechnician &&
					event.assignedTechs &&
					event.assignedTechs.toLowerCase().includes(`${assignedTechnician.first_name.toLowerCase()} ${assignedTechnician.last_name.toLowerCase()}`)
				);
			});
		}

		if (filterOptions.status) {
			filteredEvents = filteredEvents.filter((event) => event.status === filterOptions.status);
		}

		if (filterOptions.priority) {
			filteredEvents = filteredEvents.filter((event) => event.priority.toLowerCase() === filterOptions.priority.toLowerCase());
		}

		console.log('Filtered events:', filteredEvents); // Debug line
		setFilteredEvents(filteredEvents);
		setEvents(filteredEvents);
	};

	const handleClearFilters = () => {
		const initialFilterOptions = {
			startDate: '',
			endDate: '',
			customer: '',
			technician: '',
			status: '',
			priority: '',
		};
		setFilterOptions(initialFilterOptions);
	};

	return (
		<div className="navigator-container">
			<div>
				{userRole === 'admin' && (
					<button className="nav-button" onClick={handleAddEventClick}>
						Add Job Order
					</button>
				)}
			</div>

			<div className="rightSide">
				<form onSubmit={handleSearchSubmit}>
					<input onChange={handleSearchChange} type="text" placeholder="Search" />
					<button type="submit">
						<img src={SearchIcon} alt="Search" style={{ height: '3vh' }} />
					</button>
				</form>

				<button className="nav-button" onClick={handleFilterToggle}>
					<img src={FilterIcon} alt="Search" style={{ marginRight: '5px', height: '1.7vh' }} /> <p>Filters</p>
				</button>
			</div>

			{isFilterMenuOpen && (
				<div className="filter-menu-container" ref={filterMenuRef}>
					<div className="filter-menu">
						<div className="filter-grid-container">
							<div className="filter-grid-item filter-label">
								<label htmlFor="customer">Customer</label>
							</div>
							<div className="filter-grid-item filter-label">
								<label htmlFor="technician">Technician</label>
							</div>
							<div className="filter-grid-item">
								<select id="customer" value={filterOptions.customer} onChange={(e) => handleFilterChange('customer', e.target.value)}>
									<option value="">Select Customer</option>
									{customers.map((customer) => (
										<option key={customer.id} value={customer.id}>
											{`${customer.first_name} ${customer.last_name}`}
										</option>
									))}
								</select>
							</div>
							<div className="filter-grid-item">
								<select id="technician" value={filterOptions.technician} onChange={(e) => handleFilterChange('technician', e.target.value)}>
									<option value="">Select Technician</option>
									{technicians.map((technician) => (
										<option key={technician.id} value={`${technician.id} `}>
											{`${technician.first_name} ${technician.last_name}`}
										</option>
									))}
								</select>
							</div>
							<div className="filter-grid-item filter-label">
								<label>Status</label>
							</div>
							<div className="filter-grid-item filter-label">
								<label>Priority</label>
							</div>
							<div className="filter-grid-item">
								<button
									id="open"
									className={`filter-status-button ${filterOptions.status === 'open' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('status', 'open');
									}}
								>
									Open
								</button>
								<button
									id="done"
									className={`filter-status-button ${filterOptions.status === 'done' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('status', 'done');
									}}
								>
									Done
								</button>
								<button
									id="canceled"
									className={`filter-status-button ${filterOptions.status === 'canceled' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('status', 'canceled');
									}}
								>
									Canceled
								</button>
							</div>
							<div className="filter-grid-item">
								<button
									className={`filter-priority-button ${filterOptions.priority === 'low' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('priority', 'low');
									}}
								>
									Low
								</button>
								<button
									className={`filter-priority-button ${filterOptions.priority === 'medium' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('priority', 'medium');
									}}
								>
									Medium
								</button>
								<button
									className={`filter-priority-button ${filterOptions.priority === 'high' ? 'selected' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										handleFilterChange('priority', 'high');
									}}
								>
									High
								</button>
							</div>
						</div>
						<div className="filter-grid-item-full">
							<button className="clear-button" onClick={handleClearFilters}>
								Clear All
							</button>
							<button className="apply-button" onClick={applyFilters}>
								Apply
							</button>
						</div>
					</div>
				</div>
			)}

			{isAddModalOpen && <AddJobOrderModal handleCloseModal={handleCloseAddModal} setEvents={setEvents} user={user} />}
		</div>
	);
};

export default Toolbar;
