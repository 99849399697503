import React, { useEffect, useState, useCallback } from 'react';
import './UserAccount.css';
import EditIcon from '../../assets/svg/editIcon.svg';
import EyeIcon from '../../assets/svg/eye-slash.svg';

const UserAccount = ({ user }) => {
	const [userData, setUserData] = useState({});
	const [companyName, setCompanyName] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [loadingUserData, setLoadingUserData] = useState(true);
	const [loadingCompanyName, setLoadingCompanyName] = useState(true);
	const [loadingProfilePicture, setLoadingProfilePicture] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const formatDateWithoutTime = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: '2-digit',
		});
	};

	// Wrapping fetchUserData in useCallback to memoize the function
	const fetchUserData = useCallback(async () => {
		try {
			const techniciansResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user-profile-info/${user.id}`);
			if (!techniciansResponse.ok) {
				throw new Error('Failed to fetch user info');
			}
			const techniciansData = await techniciansResponse.json();
			setUserData(techniciansData);
		} catch (error) {
			console.error('Error fetching user details:', error);
		} finally {
			setLoadingUserData(false);
		}
	}, [user.id]); // Depend on user.id to ensure it updates correctly when user changes

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]); // Use the memoized fetchUserData as the dependency

	useEffect(() => {
		const fetchUserCompanyName = async () => {
			try {
				const companyResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/user-company-name/${user.account_id}`);
				if (!companyResponse.ok) {
					throw new Error('Failed to fetch company name');
				}
				const companyData = await companyResponse.json();
				setCompanyName(companyData);
			} catch (error) {
				console.error('Error fetching account technicians:', error);
			} finally {
				setLoadingCompanyName(false);
			}
		};

		fetchUserCompanyName();
	}, [user]);

	const handleEditClick = () => {
		document.getElementById('fileInput').click();
	};

	const updateProfilePicture = async (file, techId) => {
		try {
			const formData = new FormData();
			formData.append('profile_picture', file);

			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/update-user-profile-picture/${techId}`, {
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				const responseData = await response.json();
				console.log(responseData.message);
			} else {
				console.error('Failed to update profile picture.');
			}
		} catch (error) {
			console.error('Error updating profile picture:', error);
		}
	};

	const handleFileChange = async (event) => {
		const file = event.target.files[0];

		if (file) {
			try {
				setLoadingProfilePicture(true);
				await updateProfilePicture(file, user.id);
				fetchUserData();
				setLoadingProfilePicture(false);
			} catch (error) {
				console.error('Error handling file change:', error);
			}
		}
	};

	const handleUpdate = async () => {
		if (newPassword === confirmNewPassword) {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/technicians/password-update/${user.id}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: userData.email,
						currentPassword,
						newPassword,
					}),
				});

				if (response.ok) {
					console.log('Password updated successfully');
				} else {
					console.error('Failed to update password');
				}
			} catch (error) {
				console.error('Error updating password:', error);
			}
		} else {
			console.log("Passwords don't match");
			console.error("Passwords don't match");
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	if (loadingUserData || loadingCompanyName) return <div className="loader"></div>;

	return (
		<div className="edit-profile-container">
			<div className="grid-container">
				<div className="profile-picture-container">
					<div className="section-title">Profile Image</div>
					<div className="profile-picture-section">
						<div className="profile-picture">{loadingProfilePicture ? <div className="loader"></div> : <img src={userData.profile_picture_url} alt="Profile" />}</div>
						<button className="profileButton" onClick={handleEditClick}>
							<img src={EditIcon} alt="edit" className="editButtonIcon" />
							&nbsp;Edit
						</button>
						<input type="file" id="fileInput" name="profile_picture" style={{ display: 'none' }} onChange={handleFileChange} accept="image/*" />
					</div>
				</div>
				<div className="user-info-container">
					<div className="section-title">User Information</div>
					<div className="personal-info-section">
						<div className="info-section">
							<p className="profileTitle">Name</p>
							<p className="profileInfo">
								{userData.first_name} {userData.last_name}
							</p>
							<p className="profileTitle">Date of Birth</p>
							<p className="profileInfo">{formatDateWithoutTime(userData.dateofbirth)}</p>

							<p className="profileTitle">City / Province / Country</p>
							<p className="profileInfo">
								{userData.city} / {userData.province} / {userData.country}
							</p>
						</div>
						<div className="company-info-section">
							<p className="profileTitle">Company</p>
							<p className="profileInfo">{companyName}</p>

							<p className="profileTitle">Email</p>
							<p className="profileInfo">{userData.email}</p>
						</div>
					</div>
				</div>
				<div className="password-container">
					<div className="section-title">Password</div>
					<div className="password-section">
						<div className="password-input-container">
							<input
								placeholder="Current Password"
								type={showPassword ? 'text' : 'password'}
								name="currentPassword"
								className="passwordTextArea"
								value={currentPassword}
								onChange={(e) => setCurrentPassword(e.target.value)}
							/>
							<img src={EyeIcon} alt="Toggle visibility" className="eyeIcon" onClick={toggleShowPassword} />
						</div>
						<div className="password-input-container">
							<input
								placeholder="New Password"
								type={showPassword ? 'text' : 'password'}
								name="newPassword"
								className="passwordTextArea"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<img src={EyeIcon} alt="Toggle visibility" className="eyeIcon" onClick={toggleShowPassword} />
						</div>
						<div className="password-input-container">
							<input
								placeholder="Confirm New Password"
								type={showPassword ? 'text' : 'password'}
								name="confirmNewPassword"
								className="passwordTextArea"
								value={confirmNewPassword}
								onChange={(e) => setConfirmNewPassword(e.target.value)}
							/>
							<img src={EyeIcon} alt="Toggle visibility" className="eyeIcon" onClick={toggleShowPassword} />
						</div>
						<button className="profileButton2" type="submit" onClick={handleUpdate}>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserAccount;
