//TechniciansToolbar.js

import React, { useState } from 'react';
import InviteTechnicianModal from '../modals/InviteTechnicianModal';
import './Toolbar.css';
import SearchIcon from '../../assets/svg/search.svg';
import Autosuggest from 'react-autosuggest';

const TechniciansToolbar = ({ technicians, setFilteredTechnicians, setTechnicians, user }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);

	// Function to handle successful invitation
	const handleSuccessfulInvitation = () => {
		setShowSuccessPopup(true);
		setTimeout(() => {
			setShowSuccessPopup(false);
		}, 8000);
	};

	// updating search term on input change
	const handleSearchChange = (event, { newValue }) => {
		setSearchTerm(newValue);
	};

	// submitting the search form
	const handleSearchSubmit = (event) => {
		event.preventDefault();

		const searchFilter =
			searchTerm === ''
				? technicians
				: technicians.filter(
						(Technician) =>
							(Technician.first_name && Technician.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(Technician.last_name && Technician.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(Technician.first_name + ' ' + Technician.last_name).toLowerCase().includes(searchTerm.toLowerCase()) ||
							(Technician.email && Technician.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(Technician.city && Technician.city.toLowerCase().includes(searchTerm.toLowerCase())) ||
							(Technician.province && Technician.province.toLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
							(Technician.country && Technician.country.toLowerCase().includes(searchTerm.toLowerCase()))
				  );

		setFilteredTechnicians(searchFilter);
	};

	const getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: technicians.filter(
					(Technician) =>
						(Technician.first_name && Technician.first_name.toLowerCase().includes(inputValue)) ||
						(Technician.last_name && Technician.last_name.toLowerCase().includes(inputValue)) ||
						(Technician.email && Technician.email.toLowerCase().includes(inputValue)) ||
						(Technician.city && Technician.city.toLowerCase().includes(inputValue)) ||
						(Technician.province && Technician.province.toLowerCase().includes(inputValue)) ||
						(Technician.country && Technician.country.toLowerCase().includes(inputValue))
			  );
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const renderSuggestion = (suggestion, { query }) => {
		const fullName = `${suggestion.first_name} ${suggestion.last_name}`;
		const email = suggestion.email;

		const city = suggestion.city;
		const country = suggestion.country;
		const province = suggestion.province;
		console.log(suggestion);
		const parts = fullName.split(new RegExp(`(${query})`, 'gi'));

		const showAdditionalInfo =
			email.toLowerCase().includes(query.toLowerCase()) ||
			city.toLowerCase().includes(query.toLowerCase()) ||
			province.toLowerCase().includes(query.toLowerCase()) ||
			country.toLowerCase().includes(query.toLowerCase());

		return (
			<div>
				{parts.map((part, index) =>
					part.toLowerCase() === query.toLowerCase() ? (
						<span key={index} style={{ fontWeight: 'bold', color: '#13d1ff' }}>
							{part}
						</span>
					) : (
						part
					)
				)}
				{showAdditionalInfo && (
					<span style={{ color: '#13d1ff', marginLeft: '5px' }}>
						(
						{email.toLowerCase().includes(query.toLowerCase())
							? 'email'
							: city.toLowerCase().includes(query.toLowerCase())
							? 'In ' + city
							: province.toLowerCase().includes(query.toLowerCase())
							? 'In ' + province
							: country.toLowerCase().includes(query.toLowerCase())
							? 'In ' + country
							: ''}
						)
					</span>
				)}
			</div>
		);
	};

	// opening the "Add Job Order" modal
	const handleAddEventClick = () => {
		setAddModalOpen(true);
	};

	// closing the "Add Job Order" modal
	const handleCloseAddModal = () => {
		setAddModalOpen(false);
	};

	return (
		<div className="navigator-container">
			<div>
				<button className="nav-button" onClick={handleAddEventClick}>
					Add Technician
				</button>
			</div>

			<div className="rightSide">
				<form onSubmit={handleSearchSubmit}>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						getSuggestionValue={(suggestion) => suggestion.first_name + ' ' + suggestion.last_name}
						renderSuggestion={renderSuggestion}
						inputProps={{
							placeholder: 'Search',
							value: searchTerm,
							onChange: handleSearchChange,
						}}
					/>
					<button type="submit">
						<img src={SearchIcon} alt="Search" className="searchImage" />
					</button>
				</form>
			</div>

			{isAddModalOpen && <InviteTechnicianModal handleCloseModal={handleCloseAddModal} user={user} handleSuccessfulInvitation={handleSuccessfulInvitation} />}

			{showSuccessPopup && (
				<div className="success-popup">
					<p>Invitation sent successfully!</p>
				</div>
			)}
		</div>
	);
};

export default TechniciansToolbar;
