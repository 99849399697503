import React, { useState, useEffect } from 'react';
import './auth.css';
import { Link, useHistory } from 'react-router-dom';
import FullLogo from '../../assets/svg/fullLogo.svg';

const ForgotPassword = () => {
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [verificationCode, setVerificationCode] = useState('');
	const [verificationSent, setVerificationSent] = useState(false);
	const [hashedCode, setHashedCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const history = useHistory();

	useEffect(() => {
		if (step === 2 && !verificationSent) {
			handleSendVerification();
		}
	});

	const handleNext = async () => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

		if (step === 1 && !emailRegex.test(email)) {
			setErrorMessage('Invalid email address');
			console.error('Invalid email address');
			return;
		}

		// Check if the email exists by making a request to /api/emails
		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/emails?email=${email}`);
		const data = await response.json();

		if (!data.exists) {
			setErrorMessage('Email does not exist in the database');
			console.error('Email does not exist in the database');
			return;
		}

		if (step === 2) {
			// Compare entered code with the original verification code
			if (verificationCode === hashedCode) {
				console.log('Verification code is correct');
				setStep(step + 1);
			} else {
				setErrorMessage('Verification code is incorrect');
				console.error('Verification code is incorrect');
			}
			return;
		}

		if (step === 3 && password !== confirmPassword) {
			setPasswordsMatch(false);
			return;
		}

		setErrorMessage('');

		setStep(step + 1);
		setPasswordsMatch(true);
	};

	const handleSendVerification = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/users/send-verification`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
				}),
			});

			if (response.ok) {
				const data = await response.json();
				setVerificationSent(true);
				setHashedCode(data.hashedCode);
				console.log('Verification email sent successfully');
			} else {
				console.log('Failed to send verification email');
			}
		} catch (error) {
			console.error('Error sending verification email:', error);
		}
	};

	const handleRecovery = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/technicians/password-recovery`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					password,
				}),
			});

			if (response.ok) {
				console.log('Recovery successful');
				history.push('/login');
			} else {
				console.log('Recovery failed');
			}
		} catch (error) {
			console.error('Error during recovery:', error);
		}
	};

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<>
						<div className="input-group">
							<p className="labelForgetPassword">Enter your registered email address to reset the password.</p>
							<input className="logInput" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						{errorMessage && <p className="err-message">{errorMessage}</p>}
						<button className="logButton" onClick={handleNext} disabled={!email}>
							Submit
						</button>
					</>
				);
			case 2:
				return (
					<>
						{verificationSent ? (
							<>
								<div className="input-group">
									<input className="logInput" type="text" placeholder="Verification Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
								</div>
								{errorMessage && <p className="err-message">{errorMessage}</p>}
								<button className="logButton" onClick={handleNext} disabled={!verificationCode}>
									Verify Code
								</button>
							</>
						) : (
							<p className="err-message2">Verification code will be sent to your email.</p>
						)}
					</>
				);
			case 3:
				return (
					<>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
							{!passwordsMatch && <p className="err-message">Passwords do not match.</p>}
						</div>
						<button className="logButton" onClick={handleRecovery}>
							Reset Password
						</button>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<div className="authContainer">
			<div className="logoContainer">
				<img src={FullLogo} alt="Logo" className="FullLogo" />
			</div>
			<div className="login-container">
				<p className="title-header">Forgot Password</p>

				<div className="logContent">{renderStep()}</div>
			</div>
			{step !== 1 && (
				<div className="outerText">
					<p className="signup-link" onClick={() => setStep(step - 1)}>
						&lt; Back
					</p>
				</div>
			)}
			{step === 1 && (
				<div className="outerText">
					<p className="signup-text">
						<Link to="/login" className="signup-link">
							&lt; &nbsp;Back to Sign-in
						</Link>
					</p>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;
