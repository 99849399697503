import React, { useState, useEffect, useCallback } from 'react';
import CustomersToolbar from '../toolbar/CustomersToolbar';
import CustomerDetailsModal from '../modals/CustomerDetailsModal';
import CustomerEditModal from '../modals/CustomerEditModal';
import Dots from '../../assets/svg/dots.svg';
import './Views.css';

const Customers = ({ user }) => {
	const [customers, setCustomers] = useState([]);
	const [filteredCustomers, setFilteredCustomers] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(null);
	const [showEditModal, setShowEditModal] = useState(null);

	const fetchCustomers = useCallback(async () => {
		try {
			const customersResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${user.account_id}/account`);
			if (!customersResponse.ok) {
				throw new Error('Failed to fetch customers');
			}
			const customersData = await customersResponse.json();
			const customersWithMenu = customersData.map((customer) => ({
				...customer,
				isOpen: false,
			}));
			setCustomers(customersWithMenu);
			setFilteredCustomers(customersWithMenu);
		} catch (error) {
			console.error('Error fetching customers:', error);
		} finally {
			setLoading(false);
		}
	}, [user.account_id]);

	useEffect(() => {
		fetchCustomers();
	}, [fetchCustomers]);

	const handleRowClick = (customerId) => {
		setSelectedCustomer(customerId);
		console.log(customerId);
		setShowModal(true);
	};

	const handleEditClick = (customerId) => {
		setSelectedCustomer(customerId);
		console.log(customerId);
		setShowEditModal(true);
	};

	const handleDeleteClick = async (customer) => {
		setSelectedCustomer(customer.id);
		const action = customer.is_active === '1' ? 'deactivate' : 'activate';
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/customers/${customer.id}/${action}`, {
				method: 'POST',
			});
			if (!response.ok) {
				throw new Error('Failed to delete customer');
			}
			fetchCustomers();
		} catch (error) {
			console.error('Error deleting customer:', error);
		}
	};

	const handleMenuToggle = (index) => {
		setCustomers((prevCustomers) =>
			prevCustomers.map((customer, i) => {
				if (i === index) {
					return { ...customer, isOpen: !customer.isOpen };
				}
				return { ...customer, isOpen: false };
			})
		);
		setFilteredCustomers((prevFilteredCustomers) =>
			prevFilteredCustomers.map((customer, i) => {
				if (i === index) {
					return { ...customer, isOpen: !customer.isOpen };
				}
				return { ...customer, isOpen: false };
			})
		);
	};

	const handleDotsMenuClick = (index, e) => {
		e.stopPropagation();
		handleMenuToggle(index);
	};

	const filterCustomers = (filterText) => {
		const filtered = customers.filter((customer) => {
			return (
				customer.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
				customer.last_name.toLowerCase().includes(filterText.toLowerCase()) ||
				customer.email.toLowerCase().includes(filterText.toLowerCase())
			);
		});
		setFilteredCustomers(
			filtered.map((filteredCustomer) => {
				const originalCustomer = customers.find((customer) => customer.id === filteredCustomer.id);
				return { ...filteredCustomer, isOpen: originalCustomer.isOpen };
			})
		);
	};

	const handleMenuAction = (action, customerId, customer) => {
		switch (action) {
			case 'view':
				handleRowClick(customerId);
				break;
			case 'edit':
				handleEditClick(customerId);
				break;
			case 'delete':
				handleDeleteClick(customer);
				break;
			default:
				break;
		}
		// Close the menu after performing the action
		setCustomers((prevCustomers) =>
			prevCustomers.map((customer) => ({
				...customer,
				isOpen: false,
			}))
		);
		setFilteredCustomers((prevFilteredCustomers) =>
			prevFilteredCustomers.map((customer) => ({
				...customer,
				isOpen: false,
			}))
		);
	};

	const handleOutsideClick = useCallback((event) => {
		const dropdowns = document.querySelectorAll('.dropdownDot');
		let isClickedInside = false;

		dropdowns.forEach((dropdown) => {
			if (dropdown.contains(event.target)) {
				isClickedInside = true;
			}
		});

		if (!isClickedInside) {
			setCustomers((prevCustomers) => prevCustomers.map((customer) => ({ ...customer, isOpen: false })));
			setFilteredCustomers((prevFilteredCustomers) => prevFilteredCustomers.map((customer) => ({ ...customer, isOpen: false })));
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [handleOutsideClick]);

	if (loading) return <div className="loader"></div>;

	return (
		<div>
			<CustomersToolbar customers={customers} setFilteredCustomers={setFilteredCustomers} setCustomers={setCustomers} user={user} filterCustomers={filterCustomers} />
			<div>
				<table className="customer-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Phone</th>
							<th>City</th>
						</tr>
					</thead>
					<tbody>
						{filteredCustomers.map((customer, index) => (
							<tr key={customer.id}>
								<td className="firstColumn">
									{customer.first_name} {customer.last_name}
								</td>
								<td>{customer.email}</td>
								<td>{customer.phone_number}</td>
								<td>
									{customer.city}, {customer.province}, {customer.country}
								</td>
								<td>
									<div className="dotsContainer">
										<button className="dotsMenu" onClick={(e) => handleDotsMenuClick(index, e)}>
											<img src={Dots} alt="Dots" />
										</button>
										{customer.isOpen && (
											<div className="dropdownDot">
												<button
													onClick={(e) => {
														e.stopPropagation();
														
														handleMenuAction('view', customer.id, customer);
													}}
												>
													View
												</button>
												<button
													onClick={(e) => {
														e.stopPropagation();
														handleMenuAction('edit', customer.id, customer);
													}}
												>
													Edit
												</button>
												<button
													onClick={(e) => {
														e.stopPropagation();
														handleMenuAction('delete', customer.id, customer);
													}}
												>
													{customer.is_active === '1' ? 'Deactivate' : 'Activate'}
												</button>
											</div>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{showModal && (
				<CustomerDetailsModal
					selectedCustomer={selectedCustomer}
					handleCloseModal={() => {
						setShowModal(false);
					}}
				/>
			)}
			{showEditModal && (
				<CustomerEditModal
					selectedCustomer={selectedCustomer}
					handleCloseModal={() => {
						setShowEditModal(false);
						fetchCustomers();
					}}
				/>
			)}
		</div>
	);
};

export default Customers;
