// context/UserContext.js
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const accountId = user ? user.account_id : null;

	return <UserContext.Provider value={{ user, setUser, accountId }}>{children}</UserContext.Provider>;
};
