// DataSources.js

import React, { useEffect, useState } from 'react';
import './Connections.css';
import QuickbooksLogo from '../../assets/png/quickbooks.png';

const QuickBooksDetailsPopup = ({ onClose }) => {
	// Sample details - Replace with actual details fetched from backend
	const accountDetails = {
		email: 'sample@example.com',
	};

	return (
		<div className="popup-overlay">
			<div className="popup">
				<h2>QuickBooks Account Details</h2>
				<button className="close-button" onClick={onClose}>
					Close
				</button>
				<div>
					<p>Email: {accountDetails.email}</p>
				</div>
			</div>
		</div>
	);
};

const DataSources = (user) => {
	const [hasToken, setHasToken] = useState(false);
	const [showDetailsPopup, setShowDetailsPopup] = useState(false);
	const [loading, setLoading] = useState(true); // Add loading state

	useEffect(() => {
		const fetchTokenData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/quickbooks/quickbooksCheck/token/${user.user.id}`);
				if (response.ok) {
					const data = await response.json();
					setHasToken(data.tokenExists);
				} else {
					throw new Error('Failed to fetch token data');
				}
			} catch (error) {
				console.error('Error fetching token data:', error);
			} finally {
				setLoading(false); // Set loading to false after fetching data
			}
		};

		fetchTokenData();
	}, [user]);

	const connectToQuickBooks = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/quickbooks/quickbooks/connect`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();

			// Open the authorization URI in a new tab
			window.open(data.authUri, '_blank');
		} catch (error) {
			console.error('There was a problem with your fetch operation:', error);
		}
	};

	const viewQuickBooksDetails = () => {
		console.log('View QuickBooks Details');
		setShowDetailsPopup(true);
	};

	if (loading) return <div className="loader"></div>;

	return (
		<div className="datasource-table">
			<div className="grid-container-connections">
				<div className={`datasource-box ${hasToken ? 'connected' : 'disconnected'}`}>
					<div className="datasource-header">
						<img src={QuickbooksLogo} alt="QuickBooks Logo" className="logo" />
					</div>
					<div className="datasource-body">
						{hasToken ? (
							<button className="connectionsButton" onClick={viewQuickBooksDetails}>
								Details
							</button>
						) : (
							<button className="connectionsButton" onClick={connectToQuickBooks}>
								Connect
							</button>
						)}
					</div>
					{showDetailsPopup && <QuickBooksDetailsPopup onClose={() => setShowDetailsPopup(false)} />}
				</div>
			</div>
		</div>
	);
};

export default DataSources;
