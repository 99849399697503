import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './auth.css';
import { Link, useHistory } from 'react-router-dom';
import FullLogo from '../../assets/svg/fullLogo.svg';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const CompleteProfile = () => {
	const location = useLocation();
	const history = useHistory();

	const [step, setStep] = useState(1);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordsMatch, setPasswordsMatch] = useState(true);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [country, setCountry] = useState('');
	const [province, setProvince] = useState('');
	const [city, setCity] = useState('');
	const [email, setEmail] = useState('');
	const [accountId, setAccountId] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [invitationExpired, setInvitationExpired] = useState(false);
	const [emailExists, setEmailExists] = useState(false);
	const [isUnderAge, setIsUnderAge] = useState(false);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);

		const emailParam = searchParams.get('hash3'); // hash3 is an alias for the email
		setEmail(emailParam);

		const accountIdParam = searchParams.get('hash1'); // hash1 is an alias for the accountId
		setAccountId(accountIdParam);

		const isAdminParam = searchParams.get('hash2'); // hash2 is an alias for the isAdmin
		setIsAdmin(isAdminParam);

		const timestampParam = searchParams.get('hash4'); // hash4 is an alias for the timestamp
		const timestamp = new Date(timestampParam).getTime(); // Convert timestamp to milliseconds
		const currentTimestamp = new Date().getTime(); // Current timestamp in milliseconds
		const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

		if (currentTimestamp - timestamp > twentyFourHours) {
			setInvitationExpired(true);
		}

		// Check if email exists
		const checkEmailExists = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/emails?email=${emailParam}`);
				const data = await response.json();

				setEmailExists(data.exists);
			} catch (error) {
				console.error('Error checking email existence:', error);
			}
		};

		checkEmailExists();
	}, [location.search, setEmail, setAccountId, setIsAdmin]);
	const handleDateOfBirthChange = (e) => {
		console.log(e);
		const newDOB = e;
		const currentDate = new Date();
		const selectedDOB = new Date(newDOB);

		// Calculate age based on the selected date of birth
		let age = currentDate.getFullYear() - selectedDOB.getFullYear();

		if (age < 18) {
			// Technician is under 18 years old, prevent editing the date of birth
			console.error('Technician is under 18 years old. Cannot edit date of birth.');

			setIsUnderAge(true);

			return;
		}

		setDateOfBirth(e);
		setIsUnderAge(false);
	};

	const handleNext = () => {
		if (step === 1 && password !== confirmPassword) {
			setPasswordsMatch(false);
			return;
		}

		// setErrorMessage("");
		setStep(step + 1);
		setPasswordsMatch(true);
	};

	const handleComplete = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/complete-profile`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					accountId: accountId,
					email: email,
					password,
					firstName,
					lastName,
					dateOfBirth,
					country,
					province,
					city,
					isAdmin: isAdmin,
				}),
			});

			if (response.ok) {
				console.log('Profile Completed successful');
				history.push('/login');
			} else {
				console.log('Profile Completion failed');
			}
		} catch (error) {
			console.error('Error during Profile Completion:', error);
		}
	};

	const renderStep = () => {
		if (invitationExpired) {
			return <p className="err-message">Invitation expired</p>;
		}

		if (emailExists) {
			return <p className="err-message">Email already exists</p>;
		}

		switch (step) {
			case 1:
				return (
					<>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className="input-group">
							<input className="logInput" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
							{!passwordsMatch && <p className="err-message">Passwords do not match.</p>}
						</div>
						<button className="logButton" onClick={handleNext} disabled={!password || !confirmPassword}>
							Next
						</button>
					</>
				);
			case 2:
				return (
					<div className="grid-container">
						<div className="row">
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
							</div>
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
							</div>
						</div>
						<div className="row">
							<div className="regcell one-third">
								<p className="dateText">Date of Birth</p>
							</div>
							<div className="input-group regcell two-thirds">
								<input
									className="logInput"
									type="date"
									placeholder="Date of Birth"
									value={dateOfBirth}
									onChange={(e) => handleDateOfBirthChange(e.target.value)}
									style={{ border: isUnderAge ? '1px solid red' : '1px solid #ccc' }}
								/>{' '}
							</div>
						</div>
						<div className="row">
							<div className="input-group regcell">
								<CountryDropdown className="logInput" type="text" placeholder="Country" value={country} onChange={(value) => setCountry(value)} />
							</div>
							<div className="input-group regcell">
								<RegionDropdown className="logInput" type="text" placeholder="Province" country={country} value={province} onChange={(value) => setProvince(value)} />
							</div>
							<div className="input-group regcell">
								<input className="logInput" type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
							</div>
						</div>

						<div className="row">
							<button className="logButton" onClick={handleComplete} disabled={!firstName || !lastName || !dateOfBirth || !country || !province || !city}>
								Complete Profile
							</button>
							{isUnderAge && <p style={{ color: 'red' }}>Technician must be older than 18 years old</p>}
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="authContainer">
			<div className="logoContainer">
				<img src={FullLogo} alt="Logo" className="FullLogo" />
			</div>
			<div className="login-container">
				<p className="title-header">Complete Profile</p>
				<div className="logContent">{renderStep()}</div>
			</div>
			{step !== 1 && (
				<div className="outerText">
					<p className="signup-link" onClick={() => setStep(step - 1)}>
						&lt; Back
					</p>
				</div>
			)}
			{step === 1 && (
				<div className="outerText">
					<p className="signup-text">
						&lt; &nbsp;
						<Link to="/login" className="signup-link">
							Back to Sign-in
						</Link>
					</p>
				</div>
			)}
		</div>
	);
};

export default CompleteProfile;
