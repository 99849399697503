// ServiceInventoryToolbar.js

import React, { useState } from 'react';
import AddInventoryModal from '../modals/AddInventoryModal';
import './Toolbar.css';
import SearchIcon from '../../assets/svg/search.svg';
import Autosuggest from 'react-autosuggest';

const ServiceInventoryToolbar = ({ services, setFilteredServices, user }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [suggestions, setSuggestions] = useState([]);

	// updating search term on input change and filtering services
	const handleSearchChange = (event, { newValue }) => {
		setSearchTerm(newValue);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();

		const searchFilter = searchTerm === '' ? services : services.filter((service) => service.title.toLowerCase().includes(searchTerm.toLowerCase()));

		setFilteredServices(searchFilter);
	};

	const getSuggestions = (value) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		return inputLength === 0 ? [] : services.filter((service) => service.title.toLowerCase().includes(inputValue));
	};

	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const renderSuggestion = (suggestion) => <div>{suggestion.title}</div>;

	const handleAddInventoryClick = () => {
		setAddModalOpen(true);
	};

	// closing the "Add Job Order" modal
	const handleCloseAddModal = () => {
		setAddModalOpen(false);
	};

	return (
		<div className="navigator-container">
			<div>
				<button className="nav-button" onClick={handleAddInventoryClick}>
					Add Inventory
				</button>
			</div>

			<div className="rightSide">
				<form onSubmit={handleSearchSubmit}>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						getSuggestionValue={(suggestion) => suggestion.title}
						renderSuggestion={renderSuggestion}
						inputProps={{
							placeholder: 'Search',
							value: searchTerm,
							onChange: handleSearchChange,
						}}
					/>
					<button type="submit">
						<img src={SearchIcon} alt="Search" className="searchImage" />
					</button>
				</form>
			</div>

			{isAddModalOpen && <AddInventoryModal handleCloseModal={handleCloseAddModal} user={user} />}
		</div>
	);
};

export default ServiceInventoryToolbar;
