import React, { useState, useEffect } from 'react';
import './AddInventoryModal.css';
import PlusIcon from '../../assets/svg/plusIcon.svg';

const AddInventoryModal = ({ handleCloseModal, user }) => {
	const [step, setStep] = useState(1);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [typeTree, setTypeTree] = useState([]);
	const [newCategory, setNewCategory] = useState('');
	const [newType, setNewType] = useState('');
	const [categories, setCategories] = useState([]);
	const [isLoadingTypes, setIsLoadingTypes] = useState(false);
	const [selectedService, setSelectedService] = useState([]);
	const [currentTypes, setCurrentTypes] = useState([]);
	const [parentTypes, setParentTypes] = useState([]);

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/inventory/service-categories-inventory/${user.account_id}`);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();
				setCategories(data);
			} catch (error) {
				console.error('Error fetching categories', error);
			}
		};

		fetchCategories();
	}, [user]);

	const fetchTypes = async (categoryId) => {
		try {
			setIsLoadingTypes(true);
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/inventory/service-types/${categoryId}/${user.account_id}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			// console.log('Fetched types for category', categoryId, ':', data);

			const buildTree = (list) => {
				const map = {};
				const roots = [];
				list.forEach((item) => {
					map[item.id] = { ...item, children: [] };
				});
				list.forEach((item) => {
					if (item.parent_type_id) {
						map[item.parent_type_id].children.push(map[item.id]);
					} else {
						roots.push(map[item.id]);
					}
				});
				return roots;
			};

			const tree = buildTree(data);
			setTypeTree(tree);
			setCurrentTypes(tree); // Set initial current types to root types
			setParentTypes([]); // Reset parent types
		} catch (error) {
			console.error('Error fetching types', error);
		} finally {
			setIsLoadingTypes(false);
		}
	};

	const handleNext = () => {
		console.log('Current step:', step);
		if (step === 1 && selectedCategory) {
			console.log('Selected category:', selectedCategory);
			setStep(step + 1);
			fetchTypes(selectedCategoryId); // Fetch types when a category is selected
		} else if (step >= 2 && step <= 4 && newType.trim() !== '') {
			// Adjusted the condition to step <= 4
			console.log('Adding new type:', newType);
			handleAddType();
		}
	};

	const handlePrevious = () => {
		// console.log('Previous step:', step - 1);
		setStep(step - 1);
		const previousParent = parentTypes[parentTypes.length - 1];
		setCurrentTypes(previousParent ? previousParent.children : typeTree);
		setParentTypes(parentTypes.slice(0, -1)); // Remove the last parent type
	};

	const handleCategorySelect = (category) => {
		console.log('Category selected:', category);
		setSelectedCategory(category.title);
		setSelectedCategoryId(category.id);
		setTypeTree([]);
		setSelectedService([]); // Clear previous selections
		setCurrentTypes([]);
		setParentTypes([]);
	};

	const handleTypeSelect = (type) => {
		// console.log('Type selected:', type);
		const newSelectedService = [...selectedService];

		let currentLevel = newSelectedService;
		for (let i = 1; i < step - 1; i++) {
			currentLevel = currentLevel[currentLevel.length - 1].children;
		}

		const newTypeObject = {
			id: type.id,
			title: type.title,
			parent_type_id: type.parent_type_id,
			children: [],
		};

		currentLevel.push(newTypeObject);
		setSelectedService(newSelectedService);
		setParentTypes([...parentTypes, { children: currentTypes }]);
		setCurrentTypes(type.children);
		setStep(step + 1); // Move to the next step when a type is selected
	};

	const handleAddCategory = () => {
		if (newCategory.trim() !== '' && !categories.some((category) => category.title === newCategory)) {
			const newCategoryObject = { id: Date.now(), title: newCategory, account_id: user.account_id };
			console.log('Adding new category:', newCategoryObject);
			setCategories([...categories, newCategoryObject]);
			handleCategorySelect(newCategoryObject);
			setNewCategory('');
		}
	};

	const handleAddType = () => {
		if (newType.trim() !== '') {
			const newTree = [...typeTree];
			let currentLevel = newTree;
			for (let i = 1; i < step - 1; i++) {
				currentLevel = currentLevel[currentLevel.length - 1].children;
			}
			const newTypeObject = { category_id: selectedCategoryId, title: newType, is_active: 1, children: [] };
			// console.log('Adding new type at step', step, ':', newTypeObject);
			currentLevel.push(newTypeObject);
			setTypeTree(newTree);
			setNewType('');
			setParentTypes([...parentTypes, { children: currentLevel }]); // Save current level as parent
			setCurrentTypes(newTypeObject.children); // Set current types to the new type's children
			setStep(step + 1);
		}
	};

	const handleFinish = () => {
		const serviceData = selectedService.map((service) => ({
			...service,
			category_id: selectedCategoryId,
		}));
		const dataToSave = {
			services: serviceData,
		};
		console.log('Final data to save:', dataToSave);
		handleSave(dataToSave);
		handleCloseModal();
	};

	const handleSave = async (data) => {
		console.log('Saving data:', data);
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/inventory/save-service-tree/${user.account_id}/${user.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const result = await response.json();
			console.log('Save successful:', result);
		} catch (error) {
			console.error('Error saving data:', error);
			alert('There was an error saving the data. Please try again.');
		}
	};

	const renderTypes = (types) => {
		return types.map((type, index) => (
			<li key={index} className={`list-item ${selectedCategory === type.title ? 'selected' : ''}`} onClick={() => handleTypeSelect(type)}>
				{type.title}
			</li>
		));
	};

	return (
		<div className="modal-overlay">
			<div className="modal">
				<div className="modal-header">
					<p className="header-text">Add Inventory</p>
					<span className="close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>
				<div className="modal-body">
					{step === 1 && (
						<div>
							<p className="inventory-label">Select Category</p>
							<div className="add-inventory-content">
								<div className="add-bar">
									<input className="add-inventory-input" type="text" placeholder="Add new category" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} />
									<img
										src={PlusIcon}
										alt="Profile"
										className={`primary-icon ${newCategory.length > 3 ? 'clickable' : 'disabled'}`}
										onClick={newCategory.length > 3 ? handleAddCategory : null}
									/>
								</div>
								<ul className="list">
									{categories.map((category) => (
										<li className={`list-item ${selectedCategory === category.title ? 'selected' : ''}`} key={category.id} onClick={() => handleCategorySelect(category)}>
											{category.title}
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
					{step > 1 &&
						step <= 5 && ( // Adjusted to step <= 5
							<div>
								<p className="inventory-label">
									Type {step - 1} for {selectedCategory}
								</p>
								<div className="add-inventory-content">
									<div className="add-bar">
										<input className="add-inventory-input" type="text" placeholder={`Add new type ${step - 1}`} value={newType} onChange={(e) => setNewType(e.target.value)} />
										<img
											src={PlusIcon}
											alt="Profile"
											className={`primary-icon ${newCategory.length > 3 ? 'clickable' : 'disabled'}`}
											onClick={newType.length > 3 ? handleAddType : null}
										/>
									</div>
									{isLoadingTypes ? <p>Loading types...</p> : <ul className="list">{renderTypes(currentTypes)}</ul>}
								</div>
							</div>
						)}
				</div>
				<div className="modal-footer">
					{step > 1 && (
						<button onClick={handlePrevious} className="button secondary">
							Previous
						</button>
					)}
					{step < 5 && (
						<button onClick={handleNext} className={`button primary ${selectedCategory.trim() !== '' || newType.trim() !== '' ? 'clickable' : 'disabled'}`}>
							Add Type
						</button>
					)}
					{((step === 2 && selectedService.length > 0) || (step >= 3 && newType.trim() !== '') || (selectedService.length > 0 && step <= 5)) && ( // Adjusted to step <= 5
						<button onClick={handleFinish} className="button success">
							Finish
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddInventoryModal;
