import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFetchUserRole } from '../../hooks/AccountDataFetching';

import './Header.css';
import SecondaryLogo from '../../assets/svg/secondaryLogo.svg';
import ProfileImages from '../../assets/svg/ProfileImages.svg';

const Header = ({ activeTab, onTabChange, onLogout, user }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [showTabs, setShowTabs] = useState(true);
	const [title, setTitle] = useState('');
	const history = useHistory();
	const dropdownRef = useRef(null);

	// Use the custom hook
	const { userRole, loading, error } = useFetchUserRole(user);

	// Handler for changing the active tab
	const handleTabClick = (tab) => {
		onTabChange(tab);
	};

	// Handler for toggling the menu open/close
	const handleMenuToggle = () => {
		setMenuOpen(!menuOpen);
	};

	// Close menu if click is outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	// for signing out
	const handleSignOut = () => {
		console.log('Signing out');
		onLogout();
	};

	const handleOpenConnections = () => {
		console.log('redirect to datasources');
		history.push('/datasources');
		onTabChange(null); // Set activeTab to null
		setShowTabs(false); // Hide tab row
		setTitle('');
		setMenuOpen(!menuOpen);
	};

	const handleOpenMain = () => {
		history.push('/monthly');
		onTabChange('Monthly'); // Set activeTab to "Monthly"
		setShowTabs(true); // Show tab row
		setTitle('');
	};

	const handleOpenUserProfile = () => {
		console.log('redirect to user profile');
		history.push('/account');
		onTabChange(null); // Set activeTab to null
		setShowTabs(false); // Hide tab row
		setTitle('Account'); // Show "Account" title
		setMenuOpen(!menuOpen);
	};

	const handleOpenServicesInventory = () => {
		console.log('redirect to inventory');
		history.push('/services-inventory');
		onTabChange(null); // Set activeTab to null
		setShowTabs(false); // Hide tab row
		setTitle('Inventory'); // Show "Inventory" title
		setMenuOpen(!menuOpen);
	};

	// Array of tab names for rendering navigation links based on userRole
	const adminTabs = ['List', 'Daily', 'Weekly', 'Monthly', 'Technicians', 'Customers'];
	const technicianTabs = ['List', 'Daily', 'Weekly', 'Monthly'];
	const tabNames = userRole === 'admin' ? adminTabs : technicianTabs;

	return (
		<div className="headerContainer">
			<header className="headerStyle">
				<div className="firstHalf">
					<div className="logoContainerHeader">
						<img src={SecondaryLogo} alt="Logo" className="SecondaryLogo" onClick={handleOpenMain} />
					</div>
					{showTabs ? (
						<div className="tabBar">
							{loading ? (
								<span>Loading tabs...</span>
							) : error ? (
								<span>Error loading tabs</span>
							) : (
								tabNames.map((tabName) => (
									<Link key={tabName} to={`/${tabName.toLowerCase()}`} className={`tab ${activeTab === tabName ? 'activeTab' : ''}`} onClick={() => handleTabClick(tabName)}>
										{tabName}
									</Link>
								))
							)}
						</div>
					) : (
						<p className="header-title">{title}</p>
					)}
				</div>
				<div className="secondHalf">
					<div className="userSection">
						<div className="userContainer">
							{user && (
								<>
									<span>
										{user.first_name} {user.last_name}
									</span>
									<br />
									{loading ? <span>Loading...</span> : error ? <span>Error loading role</span> : <span>{userRole}</span>}
								</>
							)}
						</div>
						<img src={ProfileImages} alt="Profile" className="ProfileImages" />
					</div>
					<div className="menuSection">
						<div className="menuContainer">
							<button className="mini-menu" onClick={handleMenuToggle}>
								☰
							</button>
							{menuOpen && (
								<div className="dropdownMenu" ref={dropdownRef}>
									<button onClick={handleOpenUserProfile}>Account</button>
									{userRole === 'admin' && (
										<>
											<button onClick={handleOpenServicesInventory}>Inventory</button>
											<button onClick={handleOpenConnections}>Connections</button>
										</>
									)}
									<button onClick={handleSignOut}>Sign Out</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</header>
		</div>
	);
};

export default Header;
