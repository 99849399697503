// Monthly View

import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import './calendar.css';
import JobOrderDetailsModal from '../modals/JobOrderDetailsModal';
import Toolbar from '../toolbar/Toolbar';
import { ServiceRequestsData, useFetchUserRole } from '../../hooks/AccountDataFetching';

const MyCalendar = (user) => {
	// State to store events and the selected event for modal display
	const [events, setEvents] = useState([]);
	const [filteredEvents, setFilteredEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);

	const { userRole, loading, error } = useFetchUserRole(user.user);
	const [loadingEvents, setLoadingEvents] = useState(true); // Add loading state for events

	// Effect to fetch data when the component mounts
	useEffect(() => {
		if (userRole) {
			const fetchData = async () => {
				try {
					const formattedEvents = await ServiceRequestsData(user.user);
					setEvents(formattedEvents);
				} catch (error) {
					console.error('Error fetching service requests:', error);
				} finally {
					setLoadingEvents(false); // Set loading to false after fetching data
				}
			};

			fetchData();
		}
	}, [userRole, user]);

	if (loading) return <div className="loader"></div>;
	if (error) return <p>Error: {error.message}</p>;

	// Handler for clicking on a calendar event
	const handleEventClick = (info) => {
		setSelectedEvent(info.event);
	};

	// Custom day cell content for the calendar
	const customDayCellContent = ({ date }) => {
		const dayNumberStyle = {
			fontWeight: 'bold',
			color: '#010101',
		};

		return <div style={dayNumberStyle}>{date.getDate()}</div>;
	};
	
	const renderEventContent = (eventInfo) => {
		const { title, start } = eventInfo.event;

		const startTime = start.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).replace(/:00 /, ' ');

		return (
			<div className="fc-content">
				<div className="fc-event-dot"></div>
				<div className="fc-time">{startTime}</div>
				<div className="fc-title">{title}</div>
			</div>
		);
	};
	return (
		<div>
			<Toolbar setEvents={setEvents} setFilteredEvents={setFilteredEvents} user={user} />
			{loadingEvents ? (
				<div className="loader"></div>
			) : (
				<FullCalendar
					plugins={[dayGridPlugin]}
					initialView="dayGridMonth"
					events={filteredEvents.length > 0 ? filteredEvents : events}
					eventClick={handleEventClick}
					dayMaxEvents={8}
					eventDisplay="list-item"
					headerToolbar={{
						left: 'prev',
						center: 'title',
						right: 'next',
					}}
					dayCellContent={customDayCellContent}
					dayHeaderContent={(arg) => {
						// checks if the current day is Sunday
						const isSunday = arg.date.getDay() === 0;

						// Set color based on the day
						const colorStyle = isSunday ? { color: '#FA2539' } : { color: '#010101' };

						return <span style={colorStyle}>{arg.text}</span>;
					}}
					eventContent={renderEventContent}
				/>
			)}

			{selectedEvent && <JobOrderDetailsModal selectedEvent={selectedEvent} handleCloseModal={() => setSelectedEvent(null)} setEvents={setEvents} events={events} user={user} />}
		</div>
	);
};

export default MyCalendar;
