// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasource-table {
	padding-top: 10%;
}

.grid-container-connections {
	display: grid;
	justify-content: flex-start;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	gap: 20px;
}

.datasource-box {
	width: 60vh;
	border-radius: 8px;
	padding: 20px;
	position: relative;
	background-color: white;
	border-radius: 8px;
}

.datasource-box::before {
	content: '';
	position: absolute;
	top: -10px;
	left: -10px;
	right: -10px;
	bottom: -10px;
	border: 2px solid white;
	border-radius: 8px;
	z-index: -1;
}

.datasource-box.connected::before {
	border-color: #13d1ff;
}

.datasource-box.disconnected::before {
	border-color: #d3d3d3;
}

.datasource-header {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.logo {
	width: 40vh;
}

.connectionsButton {
	cursor: pointer;
	background-color: #13d1ff;
	color: white;
	font-size: 1vw;
	padding: 10px 15px;
	margin: 0 10px;
	border: none;
	border-radius: 5px;
	width: 15vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/connections/Connections.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,2BAA2B;CAC3B,qCAAqC;CACrC,kCAAkC;CAClC,SAAS;AACV;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,aAAa;CACb,kBAAkB;CAClB,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,UAAU;CACV,WAAW;CACX,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,aAAa;CACb,6BAA6B;CAC7B,mBAAmB;AACpB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,yBAAyB;CACzB,YAAY;CACZ,cAAc;CACd,kBAAkB;CAClB,cAAc;CACd,YAAY;CACZ,kBAAkB;CAClB,WAAW;AACZ","sourcesContent":[".datasource-table {\n\tpadding-top: 10%;\n}\n\n.grid-container-connections {\n\tdisplay: grid;\n\tjustify-content: flex-start;\n\tgrid-template-columns: repeat(3, 1fr);\n\tgrid-template-rows: repeat(3, 1fr);\n\tgap: 20px;\n}\n\n.datasource-box {\n\twidth: 60vh;\n\tborder-radius: 8px;\n\tpadding: 20px;\n\tposition: relative;\n\tbackground-color: white;\n\tborder-radius: 8px;\n}\n\n.datasource-box::before {\n\tcontent: '';\n\tposition: absolute;\n\ttop: -10px;\n\tleft: -10px;\n\tright: -10px;\n\tbottom: -10px;\n\tborder: 2px solid white;\n\tborder-radius: 8px;\n\tz-index: -1;\n}\n\n.datasource-box.connected::before {\n\tborder-color: #13d1ff;\n}\n\n.datasource-box.disconnected::before {\n\tborder-color: #d3d3d3;\n}\n\n.datasource-header {\n\tdisplay: flex;\n\tjustify-content: space-around;\n\talign-items: center;\n}\n\n.logo {\n\twidth: 40vh;\n}\n\n.connectionsButton {\n\tcursor: pointer;\n\tbackground-color: #13d1ff;\n\tcolor: white;\n\tfont-size: 1vw;\n\tpadding: 10px 15px;\n\tmargin: 0 10px;\n\tborder: none;\n\tborder-radius: 5px;\n\twidth: 15vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
