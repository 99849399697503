import React, { useState } from 'react';
import './modals.css';

const InviteTechnicianModal = ({ handleCloseModal, user, handleSuccessfulInvitation }) => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [isAdmin, setIsAdmin] = useState(false);
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [clicked, setClicked] = useState(false);

	const handleInviteClick = async () => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

		if (!emailRegex.test(email)) {
			setErrorMessage('Invalid email address');
			console.error('Invalid email address');
			return;
		}
		if (!email || !firstName || !lastName) {
			// Display a message to the user or handle the action you want to take when the button is disabled
			const button = document.querySelector('.add-button');
			button.addEventListener('click', handleButtonClick);
			// return;
		}

		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/emails?email=${email}`);
		const data = await response.json();

		if (data.exists) {
			setErrorMessage('Email already exists in the database');
			console.error('Email already exists in the database');
			return;
		}

		const currentDateTime = new Date().toISOString();

		const orderResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/tech_invitation`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({
				senderFN: user.first_name,
				senderLN: user.last_name,
				accountId: user.account_id,
				email: email,
				firstName: firstName,
				lastName: lastName,
				isAdmin,
				timestamp: currentDateTime,
			}),
		});

		if (orderResponse.ok) {
			handleSuccessfulInvitation();
			handleCloseModal();
		} else {
			console.error('Failed to send invitation');
			return;
		}
	};
	const handleButtonClick = () => {
		if (!email || !firstName || !lastName) {
			setClicked(true);
			// setTimeout(() => {
			// 	setClicked(false);
			// }, 3000);
			console.log('Button clicked on disabled state');
		}
	};

	return (
		<div className="invitation-overlay">
			<div className="invite-modal">
				<div className="modal-header">
					<p className="header-text">Invite Technician</p>
					<span className="close" onClick={handleCloseModal}>
						&times;
					</span>
				</div>
				<div className="tech-error-message">{clicked && <p>Please fill out all of the fields before sending the invitation</p>}</div>

				<div className="invitation-content">
					<div className="row-sec">
						<div className="addRow">
							<input
								className="addTechCell"
								type="text7"
								placeholder="First Name"
								value={firstName}
								onChange={(e) => {
									setFirstName(e.target.value);
									setClicked(false);
								}}
								required
							/>
							<input
								className="addTechCell"
								type="text7"
								placeholder="Last Name"
								value={lastName}
								onChange={(e) => {
									setLastName(e.target.value);
									setClicked(false);
								}}
								required
							/>
						</div>
					</div>
					<div className="row-sec">
						<div className="addModalRow">
							<input
								className="addTechCell"
								type="text7"
								placeholder="Enter email address"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									setClicked(false);
								}}
								required
							/>
						</div>
					</div>

					<div className="row-sec2">
						{errorMessage && <p className="err-message">{errorMessage}</p>}
						<div className="checkbox-container">
							<input type="checkbox" checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} required />
							<label className="admin_label">Admin</label>
						</div>
						<button className="add-button" onClick={handleInviteClick} disabled={!email || !firstName || !lastName} onPointerDown={handleButtonClick}>
							Send Invitation
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InviteTechnicianModal;
