import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import './calendar.css'; // Ensure this file contains your custom styles
import JobOrderDetailsModal from '../modals/JobOrderDetailsModal';
import Toolbar from '../toolbar/Toolbar';
import { ServiceRequestsData, useFetchUserRole } from '../../hooks/AccountDataFetching';

const List = (user) => {
	// State for storing events and the selected event for modal display
	const [events, setEvents] = useState([]);
	const [filteredEvents, setFilteredEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const { userRole, loading, error } = useFetchUserRole(user.user);
	const [loadingEvents, setLoadingEvents] = useState(true); // Add loading state for events

	// Effect to load data on component mount
	useEffect(() => {
		if (userRole) {
			const fetchData = async () => {
				try {
					const formattedEvents = await ServiceRequestsData(user.user);
					setEvents(formattedEvents);
				} catch (error) {
					console.error('Error fetching service requests:', error);
				} finally {
					setLoadingEvents(false); // Set loading to false after fetching data
				}
			};

			fetchData();
		}
	}, [userRole, user]);

	if (loading) return <div className="loader"></div>;
	if (error) return <p>Error: {error.message}</p>;

	// Handler for handling click on an event
	const handleEventClick = (info) => {
		setSelectedEvent(info.event);
	};

	// Custom event content for list view
	const customListEventContent = ({ event }) => {
		const listStyle = {
			display: 'flex',
			flexDirection: 'column',
			padding: '10px',
			borderBottom: '1px solid #ddd',
			marginBottom: '10px',
			background: '#fff',
			borderRadius: '5px',
		};

		const headerStyle = {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '10px',
		};

		const dateStyle = {
			fontSize: '16px',
			fontWeight: '600',
		};

		const timeStyle = {
			fontSize: '14px',
			fontWeight: '500',
			color: '#999',
		};

		const titleStyle = {
			fontSize: '18px',
			fontWeight: '700',
		};

		const techStyle = {
			fontSize: '14px',
			color: '#666',
			display: 'flex',
			alignItems: 'center',
		};

		return (
			<div style={listStyle}>
				<div style={headerStyle}>
					<div>
						<div style={dateStyle}>{event.start.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'long' })}</div>
						<div style={timeStyle}>{event.start.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</div>
					</div>
				</div>
				<div style={titleStyle}>{event.title}</div>
				<div style={techStyle}>{event.extendedProps.assignedTechs || 'No Technician Selected'}</div>
			</div>
		);
	};

	return (
		<div>
			<Toolbar setEvents={setEvents} setFilteredEvents={setFilteredEvents} user={user} />

			{loadingEvents ? (
				<div className="loader"></div>
			) : (
				<FullCalendar
					plugins={[listPlugin]}
					initialView="listYear"
					events={filteredEvents.length > 0 ? filteredEvents : events}
					eventClick={handleEventClick}
					headerToolbar={{
						left: 'title',
					}}
					eventContent={customListEventContent}
				/>
			)}

			{selectedEvent && <JobOrderDetailsModal selectedEvent={selectedEvent} handleCloseModal={() => setSelectedEvent(null)} setEvents={setEvents} events={events} user={user} />}
		</div>
	);
};

export default List;
